
import React, { useEffect } from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Avatar from '@mui/material/Avatar';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Link } from "react-router-dom";

import { BsDot } from "react-icons/bs";

import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    width: '100%',
    borderRadius: 5,

}));


function IneligibleReasonBarChart({ percentageBarChartData }) {

    const percentageBarTheme = createTheme({
        palette: {
            passed: {

                main: '#1D4ED8',
            },
            suspend: {

                main: '#60A5FA',
            },
            ineligble: {

                main: '#2DD4BF',
            },
            review: {

                main: '#99F6E4',
            },
            incomplete: {

                main: '#FBBF24',
            },
            noresponse: {

                main: '#FDE68A',
            },
        },
    });


    function getMetricBackground(name) {
        if (name !== undefined) {

            if (name == "Relationship Not Eligible") {
                return (
                    '#FFFBEB'
                );
            }
            if (name == "Exceeds Age Requirements") {
                return (
                    '#FFFBEB'
                );
            }

            if (name == "Relationship Not Supported By Docs") {
                return (
                    '#FFFBEB'
                );
            }
            if (name == 'Divorced') {
                return (
                    '#FFFBEB'
                );
            }
            if (name == 'Legal Separation') {
                return (
                    '#FFFBEB'
                );
            }
            if (name == 'Not Full-Time Student') {
                return (
                    '#FFFBEB'
                );
            }

            if (name == "Military") {
                return (
                    '#FFFBEB'
                );
            }
            if (name == "Employee Request") {
                return (
                    '#FFFBEB'
                );
            }

            if (name == "Qualified Event") {
                return (
                    '#FFFBEB'
                );
            }
            if (name == 'Eligible for Other Coverage') {
                return (
                    '#FFFBEB'
                );
            }

            if (name == "Ineligible: Dental, Eligible: Med, Vision") {
                return (
                    '#FFFBEB'
                );
            }
            if (name == "Ineligible: Dental, Med, Eligible: Vision") {
                return (
                    '#FFFBEB'
                );
            }

            if (name == "Ineligible: Vision, Med, Eligible: Dental") {
                return (
                    '#FFFBEB'
                );
            }
            if (name == "Ineligible: Med, Eligible: Dental, Vision") {
                return (
                    '#FFFBEB'
                );
            }

            if (name == "Ineligible: Vision, Dental, Eligible: Med") {
                return (
                    '#FFFBEB'
                );
            }
        if (name == "Ineligible: Vision, Eligible: Med, Dental") {
                return (
                    '#FFFBEB'
                );
            }

    
        }
    }





    return (
        <>
            {percentageBarChartData?.map((item) => {
                return (
                    <div key={item?.metricTitle} >

                        <div className="row-no-left-margin ">

                            <div className="row-left">


                                <div>


                                    <Typography fontWeight="500" fontSize="14px" sx={{ color: '#262626' }}> {item?.metricTitle}</Typography>
                                </div>


                            </div>
                            <div className="row-right">

                                <div>

                                    <Typography fontWeight="600" fontSize="14px"> {item?.metricValue}</Typography>
                                </div>

                                <div className="halfspacer-margin-left halfquarterspacer-margin-top">

                                    <Typography fontWeight="400" fontSize="12px" >  ({item?.metricPercentage}%)</Typography>
                                </div>
                            </div>



                        </div>
                        <div className="row-no-margin spacer-margin-bottom">
                            <ThemeProvider theme={percentageBarTheme}>
                                <Box sx={{ flexGrow: 1 }}>
                                    {item?.metricTitle == 'Relationship Not Eligible' ? (<>
                                        <BorderLinearProgress aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`} color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == 'Exceeds Age Requirements' ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == 'Relationship Not Supported By Docs' ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == 'Divorced' ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == "Legal Separation" ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == "Not Full-Time Student" ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == 'Military' ? (<>
                                        <BorderLinearProgress aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`} color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == 'Employee Request' ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == 'Qualified Event' ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == 'Eligible for Other Coverage' ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == "Ineligible: Dental, Eligible: Med, Vision" ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == "Ineligible: Dental, Med, Eligible: Vision" ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == "Ineligible: Vision, Med, Eligible: Dental" ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == "Ineligible: Med, Eligible: Dental, Vision" ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == "Ineligible: Vision, Dental, Eligible: Med" ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == "Ineligible: Vision, Eligible: Med, Dental" ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                </Box>
                            </ThemeProvider>
                        </div>



                    </div>

                )
            })}
        </>
    );
}

export default IneligibleReasonBarChart;
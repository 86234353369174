
import React, { useState, useContext } from 'react';


import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { format, formatISO } from 'date-fns'

function FamilyDetailsFilesUploadedTable({ tableData, desiredWidth, desiredHeight }) {

    if (desiredWidth == undefined || desiredWidth == null) {

    }

    //from mui documentation

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        if (name !== undefined) {

            return {
                sx: {
                    //bgcolor: '#737373',
                    bgcolor: '#23549F',
                    color: '#FFFFFF',
                    fontSize: 12
                },
                children: `${name.substring(0, 3)}`,
            };
        }
    }

    function renderDate(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'MM/dd/yyyy @ h:mm a');
    }

    function renderTime(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'h:mm a');
    }

    function renderRelationship(row) {
        console.log("ROW -----", row);
        if (row === "E") {
            return 'Employee'
        }
        else if (row === "S ") {
            return 'Spouse'
        } else if (row === "D ") {
            return 'Dependent'
        } else {
            return `DBValue: ${row}`
        }
    }

    function renderMemberStatus(dataText) {
        if (dataText == "Passed") {
            return (<Chip sx={{ bgcolor: '#BBF7D0', color: '#166534' }} label={dataText} />)
        } else if (dataText == "Incomplete") {
            return (<Chip sx={{ bgcolor: '#FDE68A', color: '#92400E' }} label={dataText} />)
        } else if (dataText == "Ineligible") {
            return (<Chip sx={{ bgcolor: '#FECACA', color: '#991B1B', }} label={dataText} />)
        } else if (dataText == null) {
            return (<Chip sx={{ bgcolor: '#FECACA', color: '#991B1B', }} label={'DB: NULL'} />)
        } else {
            return (<Chip label={dataText} />)
        }
    }



    const rows = tableData;
    const navigate = useNavigate();

    const columns = [
      
        {
            "field": "fileName",
            "headerName": "File Name",
            "width": 880,
            "editable": false
        },
        {
            "field": "fileUploadTimeStamp",
            "headerName": "Received Date",
            "width": 240,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.fileUploadTimeStamp)
        },
       

    ];

    function getEmployeeRowID(row) {
        return row.rowID;
    }

    return (
        <>
            {desiredWidth ? (<>
                <div style={{ width: desiredWidth, height: desiredHeight, }}>
                    <DataGridPro
                        getRowId={getEmployeeRowID}
                        disableSelectionOnClick={true}
                        disableMultipleRowSelection={true}

                        rows={rows}
                        columns={columns}

                        sx={{
                            cursor: 'pointer',
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                                border: 'none',
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold !important',
                                overflow: 'visible !important'
                            }
                        }}
                    />
                </div>
            </>) : (<>
                <div style={{ height: 500, width: "70VW" }}>
                    <DataGridPro
                        getRowId={getEmployeeRowID}
                        disableSelectionOnClick={true}
                        disableMultipleRowSelection={true}

                        rows={rows}
                        columns={columns}

                        sx={{
                            cursor: 'pointer',
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                                border: 'none',
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold !important',
                                overflow: 'visible !important'
                            }
                        }}
                    />
                </div>
            </>)}
        </>
    );
}

export default FamilyDetailsFilesUploadedTable;
import React from 'react';

import Box from "@mui/material/Box";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import { Link } from "react-router-dom";






function ProjectCard({ projectClientName, projectClient, projectLink, desiredCardWidth, desiredCardHeight, projectCardTopText, projectCardAvatarTrue }) {

    const cardTheme = createTheme({
        palette: {
            primary: {
                main: '#FFFFFF',
            },
        },
    });

    const StyledCard = styled(Card)`
        ${({ theme }) => `
        cursor: pointer,
        background-color:  '#FFFFFF';
        transition: ${ theme.transitions.create(['background-color', 'transform'], {
                duration: theme.transitions.duration.standard,
        })};
        &:hover {
            background-color: '#FFFFFF';
            transform: scale(1.05) translateZ(0);
            backface-visibility: hidden;
            
        }
        `} 
    `;

    //from mui documentation

    const [cardSize, setCardSize] = React.useState("inherit");

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        if (name !== undefined) {
          
            return {
                sx: {
                    bgcolor: '#737373',
                    color: '#FFFFFF',
                },
                children: `${name.split(' ')[0][0]}`,
            };
        }
    }


    return (
        <>
            <Link key={projectLink} to={projectLink} >
            <StyledCard style={{ height: '100%' }} sx={{ minWidth: desiredCardWidth ? (desiredCardWidth) : (75), minHeight: desiredCardHeight ? (desiredCardHeight) : (400) }} style={{ textDecoration: 'none' }} underline="none" >
               

                {/*</CardActions>*/}
                <div onMouseEnter={() => setCardSize('80px')} onMouseLeave={() => setCardSize('inherit')}>
                    <CardContent style={{ textDecoration: 'none' }} underline="none" sx={{ ":hover": { boxShadow: 6}}}>
                        {projectCardTopText ? (<>

                            <Typography textAlign="left" sx={{ fontSize: 14, textDecoration: 'none' }} fontWeight="500" color="text.secondary" gutterBottom>
                                {projectCardTopText}
                            </Typography>

                        </>) : (<></>)}



                        <Typography textAlign="left" variant="h6" component="div" fontWeight="600" fontSize="1.45em"  underline="none" sx={{ textDecoration: 'none' }} >
                            {projectCardAvatarTrue ? (<>

                                <Avatar {...stringAvatar(projectClientName)} />

                            </>) : (<></>)}
                            {projectClientName}
                           
                        </Typography>
                        <Typography textAlign="left" sx={{ fontSize: 14, textDecoration: 'none' }} color="text.secondary" underline="none" gutterBottom style={{ textDecoration: 'none' }}>
                            {projectClient}
                        </Typography>
                        <Grid container spacing={2} sx={{ mt: 0.25 }}>
                           

                        </Grid>


                       

                    </CardContent>
                </div>
                </StyledCard>
            </Link>
        </>
    );
}

export default ProjectCard;


import { pdfjs, Document, Page } from 'react-pdf';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
//import Viewer from 'viewerjs';
import { useState, useRef, useEffect, useContext, useMemo } from 'react';
import DocumentForm from "./DocumentForm";
import Box from '@mui/material/Box';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Drawer from '@mui/material/Drawer';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import Divider from '@mui/material/Divider';
import FamilyDetails from './FamilyDetails';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';


import Button from '@mui/material/Button';
//import { ViewerContext } from "../../pages/Viewer";
import { DocViewerContext } from "../../App.js";

//import { pdfjs, Document, Page, usePageContext } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

//pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url,).toString();


export default function DocumentViewer({doc, familyDetails, employeeDetails, docDetails}){
    const [docs, setDocs] = useState(doc);


    const [fabState, setFabState] = useState(true);


    const [orientation, setOrientation] = useState(360);
    const [zoom, setZoom] = useState(0.9);
    const [pageNum, setPageNum] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [documentsLength, setDocumentsLength] = useState(0);
    const [currentDocument, setCurrentDocument] = useState(1);
    const [currentDocumentURL, setCurrentDocumentURL] = useState("");
    const [isPdf, setIsPdf] = useState(null);


    //const {
    //    docNum, numDocs, prevDocument, nextDocument, fetchBatch
    //} = useContext(DocViewerContext)

    //const canvas = document.createElement("canvas");
    //canvas.id = 'current_canvas';
    // canvas.style.width = '800';
    // canvas.style.height = '700';
    // canvas.style.overflow = 'auto';

    //const ctx = canvas.getContext("2d");


    //const [loading, setLoading] = useState(false);
    //const [file, setFile] = useState(doc[0]?.uri);
    //const [page, setPage] = useState()


    // function ViewerWrapper(){
    //     if(doc.length > 0 ){
    //         console.log("docuri", doc[0]?.uri);
    //         const viewer = new Viewer(doc[0]?.uri, { inline: true });
    //         return(<> {viewer.show()}</>);
    //     }else {
    //         return(<></>);
    //     }

    // }
    //const pageContext = usePageContext();


    // const familyDetails = [{ id: 782011, familyId: "1234-1", DependentLastName: "Last1", DependentFirstName: "First1",  DependentRelationship: "D"}, { id: 52345, familyId: "1234-1", DependentLastName: "Last2", DependentFirstName: "First2",  DependentRelationship: "S"},
    // { id: 2344546, familyId: "1234-1", DependentLastName: "Last3", DependentFirstName: "First3",  DependentRelationship: "D"}, { id: 934857, familyId: "1234-1", DependentLastName: "Last4", DependentFirstName: "First4",  DependentRelationship: "S"},];
    // const employeeDetails = [{ familyId: "1234-1", EmployeeLastName: "Last0", EmployeeFirstName: "First0", Location: "IN", AddressPre: " ", Address1: "123 S Wall ST", Address2: "APT# 5", City: "New York City", State: "NY", Zip: "10001", ZipExtension: " "}];



    function openDrawer(){
        setFabState(true);
    }
    
    function closeDrawer(){
        setFabState(false);
    }

    function rotateLeft(){
        setOrientation(orientation - 90);
    }

    function rotateRight(){
        setOrientation(orientation + 90);
    }
 
    function zoomIn(){
        if(zoom < 2.0){
            setZoom(zoom + 0.1);
        }
       
    }

    function zoomOut(){ 
        if(zoom > 0){
            setZoom(zoom - 0.1);
        }
    
    }

    function resetZoom(){
        setZoom(1.0);
      
    }

    function nextPage(){
        if(pageNum < numPages){
            setPageNum(pageNum + 1);
        }
       
    }

    function prevPage(){
        if(pageNum > 1){
            setPageNum(pageNum - 1);
        }
     
    }

    function setWheelZoom(newZoomValue) {
        /* console.log('NEW-Zoom-Value:', newZoomValue);*/
        if (newZoomValue.deltaY < 0) {
            zoomIn();
        } else if (newZoomValue.deltaY > 0) {
            zoomOut();
        }
    }

    useEffect(() => {
        if(isPdf === false){
            const img = document.getElementById('doc_img');
            img.style.transform = `scale(${zoom})`;
        }
        
    }, [zoom, setZoom]);

    useEffect(() => {
        if(isPdf === false){
            const img = document.getElementById('doc_img');
            img.style.transform = `rotate(${orientation}deg)`;
        }
    }, [orientation, setOrientation]);
  
    function Toolbar(){

        return(
            <Card sx={{ zIndex: 1300, width: '39VW', bgcolor: '#ffffff', height: '10VH', }}>
            <CardContent>
                    <Tooltip title="Previous Multipage">
                    <IconButton onClick={() => prevPage()} disabled={pageNum <= 1}><NavigateBeforeIcon /></IconButton>
                </Tooltip>
                <IconButton disabled>
                    <Typography sx={{ fontSize: 14 }} color="primary">
                        {pageNum}/{numPages}
                    </Typography>
                    </IconButton>
                    <Tooltip title="Next Multipage">
                    <IconButton onClick={() => nextPage()} disabled={pageNum >= numPages}><NavigateNextIcon /></IconButton>
                </Tooltip>
                    <Tooltip title="Zoom Out">
                        <IconButton onClick={() => zoomOut()} disabled={zoom <= 0.5}><RemoveIcon /></IconButton>
                    </Tooltip>
                {/* <Typography sx={{ fontSize: 14 }} color="primary">
                </Typography> */}
                    <Tooltip title="Zoom In">
                        <IconButton onClick={() => zoomIn()} disabled={zoom >= 2.0}><AddIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Reset Zoom">
                        <IconButton onClick={() => resetZoom()} disabled={zoom === 1.0}><ZoomOutMapIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Rotate Left">
                        <IconButton onClick={() => rotateLeft()}><RotateLeftIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Rotate Right">
                        <IconButton onClick={() => rotateRight()}><RotateRightIcon /></IconButton>
                    </Tooltip>
                    {numPages >= 2 ?
                        (<Chip color="primary" variant="outlined" label="multi-page" />) : (<></>)
                    }
                    <Box sx={{ width: '2VW '}}></Box>
                    
            </CardContent>
        </Card>
        );
    }

    function SmallToolbar() {

        return (
            <Card sx={{ zIndex: 1300, width: 570, bgcolor: '#ffffff' }}>
                <CardContent>
                    <Tooltip title="Previous Multipage">
                        <IconButton onClick={() => prevPage()} disabled={pageNum <= 1}><NavigateBeforeIcon /></IconButton>
                    </Tooltip>
                    <IconButton disabled>
                        <Typography sx={{ fontSize: 14 }} color="primary">
                            {pageNum}/{numPages}
                        </Typography>
                    </IconButton>
                    <Tooltip title="Next Multipage">
                        <IconButton onClick={() => nextPage()} disabled={pageNum >= numPages}><NavigateNextIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Zoom Out">
                        <IconButton onClick={() => zoomOut()} disabled={zoom <= 0.5}><RemoveIcon /></IconButton>
                    </Tooltip>
                    {/* <Typography sx={{ fontSize: 14 }} color="primary">
                </Typography> */}
                    <Tooltip title="Zoom In">
                        <IconButton onClick={() => zoomIn()} disabled={zoom >= 2.0}><AddIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Reset Zoom">
                        <IconButton onClick={() => resetZoom()} disabled={zoom === 1.0}><ZoomOutMapIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Rotate Left">
                        <IconButton onClick={() => rotateLeft()}><RotateLeftIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Rotate Right">
                        <IconButton onClick={() => rotateRight()}><RotateRightIcon /></IconButton>
                    </Tooltip>
                    {numPages >= 2 ?
                        (<Chip color="primary" variant="outlined" label="multi-page" />) : (<></>)
                    }
                    <Box sx={{ width: '1VW ' }}></Box>
                </CardContent>
            </Card>
        );
    }

    const [file, setFile] = useState(null);
    const currentFile = useMemo(() => ({ url: doc?.response }), [doc?.response]);

    //useEffect(() => {

    //    setFile(doc?.response);

    //}, [doc]);


    function CurrentDocument(){
        //if(document.getElementById('doc_img')){
        //    document.body.removeChild(document.getElementById('doc_img'));
        //}
        if(pageNum > numPages){
            setPageNum(1);
        }

        console.log("documentBatch - ", doc);
     

        switch (doc?.type) {
            case ("application/pdf"):

              
                setIsPdf(true);
                return(
                <>
                <Box sx={{ border: '2px solid #23549F', height: 'fit-content', width: 'fit-content', }}>
                    <Document 
                        file={doc?.response}
                        onLoadError={console.error}
                        onLoadSuccess={({ numPages: numberOfPages }) => {
                            setNumPages(numberOfPages);
                        }}
                        options={{
                            verbosity: pdfjs.VerbosityLevel.ERRORS,
                        }}
                        loading={""}
                    >
                        <Page
                            loading={""} 
                            pageNumber={pageNum}
                            scale={zoom}
                            rotate={orientation}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            
                        />
                    </Document>
                </Box>
                </>
                );
                break;
            default:
                setIsPdf(false);
               
                //const image = new Image(800);
                //image.id = 'doc_img';
                //image.src = doc?.response;
                
                //document.getElementById('imgBox').appendChild(image);
                
               
                //box.appendChild(image);
                //document.body.appendChild(image);
               return (
                <>
                       <img id={'doc_img'} src={doc?.response} alt={"document_image"} style={{ height: '100%', width: '100%', border: '2px solid #23549F' }} />
                </>
               );
               break;
            
        }
        
    }

    const [value, setValue] = useState(0);

    //disabled functionality
    //function getNextFamilyDisabled() {
    //    if (!(docNum >= numDocs - 1) == true) {
    //        console.log("TEST gNF: docNum >= numDocs - 1 CASE: TRUE")
    //        return true;
    //    } else if (!(pageNum >= numPages) == true) {
    //        console.log("TEST gNF: pageNum >= numPages CASE: TRUE")
    //        return true;
    //    } else {
    //        console.log("TEST gNF: FALSE")
    //        return false;
    //    }
    //}

    // const handleTabChange = (event, newValue) => {
    //     setValue(newValue);
    // }

    return (
        <>

            
        {
            doc ? (
                <Box>
                     

                        {/* XL DESKTOP VERSION */}
                        <Box onWheel={(e) => setWheelZoom(e)} display="flex" alignItems="center" sx={{ width: '62VW', height: '94.25VH', bgcolor: '#F6F7F8', ml: '0VW', mr: '0VW', display: { xs: 'none', md: 'none', xl: 'block' }}}>
                           {/* <Box display="flex" alignItems="center" sx={{ width: '58VW', height: '100VH', overflow: 'auto', overflowY: 'auto' }}>*/}
                           {/* <div id="imgBox" className="overflow: auto, overflowY: 'auto'">*/}
                           <div id="imgBox" className="overflow: auto, overflowY: 'none'">

                                   

                                  {/*  <Box sx={{ border: '2px solid #23549F', height: 'fit-content', width: 'fit-content', ml: '10VW', mt: '6VH', mr: '2VW', display: { xs: 'none', md: 'none', xl: 'block' }}}>*/}
                                <Box sx={{ height: 'fit-content', width: 'fit-content', ml: '10VW', mt: '6VH', mr: '2VW', display: { xs: 'none', md: 'none', xl: 'block' }  }}>
                                    <CurrentDocument />
                                </Box>
                                  {/*  </Box>*/}

                         </div>
                           
                           
                           
                        </Box>
                    {docDetails ? (
                    <>
                    <Box sx={{ zIndex: 1350, display: { xs: 'none', md: 'none', xl: 'block', }, overflow: 'hidden',}}>
                              <Drawer
                                sx={{
                                    bgcolor: '#e6fff2',
                                    width: '39%',
                                    flexShrink: 0,
                                   
                                    '& .MuiDrawer-paper': {
                                        width: '39%',
                                      
                                    },
                           
                                }}
                                open={fabState}
                                variant="persistent"
                                anchor="right"
                               >

                                        {/*<Box display="flex" alignItems="center" sx={{ width: 740, height: '5VH', bgcolor: '#ffffff', shadowColor: '#E5E5E5', borderBottom: '2px solid #e5e5e5', }}>*/}
                                        {/*    <Tooltip title="Previous File">*/}
                                        {/*        <IconButton onClick={() => prevDocument()} disabled={docNum === 0} sx={{ ml: 'auto' }}><NavigateBeforeIcon /></IconButton>*/}
                                        {/*    </Tooltip>*/}
                                        {/*    <h3>{docNum + 1}/{numDocs}</h3>*/}
                                        {/*    <Tooltip title="Next File">*/}
                                        {/*        <IconButton onClick={() => nextDocument()} disabled={docNum >= numDocs - 1}><NavigateNextIcon /></IconButton>*/}
                                        {/*    </Tooltip>*/}
                                        {/*    {(numPages > 1) == true ? (<>*/}
                                        {/*        <Chip color="warning" label={'MultiPage Document'} />*/}
                                        {/*    </>) : (<></>)}*/}
                                         

                                        {/*    */}{/* TODO - add Box here with a 2VW width, separate out these controls  */}
                                        {/*    <Box sx={{ width: '22VW' }}></Box>*/}
                                        {/*    <Tooltip title="Get Files from Next Family">*/}
                                        {/*    <Button variant="contained" onClick={() => fetchBatch()} disabled={getNextFamilyDisabled() ? true: false} sx={{ bgcolor: '#0059a8' }} sx={{ mr: '2rem' }}>*/}
                                        {/*        Next Family*/}
                                        {/*    </Button>*/}
                                        {/*    </Tooltip>*/}
                                        {/*</Box>*/}


                        <Box sx={{ height: '90VH', bgcolor: '#eff6ff' }}>
                            <Box display="flex" alignItems="top" sx={{ width: '30VW', mt: '8VH', ml: '0.5VW'}}>

                                      

                                            


                                <DocumentForm
                                    familyDetails={familyDetails}
                                    employeeDetails={employeeDetails}
                                    docDetails={docDetails}
                                />
                                        
                                           
                                <FamilyDetails 
                                    familyDetails={familyDetails}
                                    employeeDetails={employeeDetails}
                                            />

                                               
                            </Box>
                                            
                        </Box>
               
                          <Box sx={{ alignContent: 'stretch'}}>
                            <Toolbar />
                          </Box>
                      
                      </Drawer>
                    </Box>
                                {/* FAB BUTTON */}

                                {/*<Fab sx={{ position: 'fixed', bottom: 50, right: 50 }} aria-label="edit menu" color='primary' onClick={() => openDrawer()}><EditIcon/></Fab>*/}
                    </>)
                    
                            : (<></>)}


                        {/* SM and MD DESKTOP VERSION */}
                        <Box onWheel={(e) => setWheelZoom(e)} display="flex" alignItems="center" sx={{ width: '62VW', height: '94.25VH', bgcolor: '#F6F7F8', ml: '0VW', mr: '0VW', display: { xs: 'block', md: 'block', xl: 'none' } }}>
                            {/* <Box display="flex" alignItems="center" sx={{ width: '58VW', height: '100VH', overflow: 'auto', overflowY: 'auto' }}>*/}
                            <div id="imgBox" className="overflow: auto, overflowY: 'none'">




                                    
                              {/*   <Box sx={{ border: '2px solid #23549F', height: 'fit-content', width: 'fit-content', ml: '6VW', display: { xs: 'block', md: 'block', xl: 'none' } }}>*/}
                                <Box sx={{ height: 'fit-content', width: 'fit-content', ml: '6VW', display: { xs: 'block', md: 'block', xl: 'none' }  }}>
                                        <CurrentDocument />

                                </Box>
                                 {/*</Box>*/}

                            
                            </div>



                        </Box>
                        {docDetails ? (
                            <>
                                <Box sx={{ zIndex: 1350, display: { xs: 'block', md: 'block', xl: 'none' }, width: 'auto' }}>
                                    <Drawer
                                        sx={{
                                            bgcolor: '#e6fff2',
                                            width: 570,
                                            flexShrink: 0,
                                            '& .MuiDrawer-paper': {
                                                width: 570,
                                            },
                                        }}
                                        open={fabState}
                                        variant="persistent"
                                        anchor="right">

                                        {/*<Box display="flex" alignItems="center" sx={{ width: 640, height: '5VH', bgcolor: '#ffffff', shadowColor: '#E5E5E5', borderBottom: '2px solid #e5e5e5', }}>*/}
                                        {/*    <Tooltip title="Previous Document">*/}
                                        {/*        <IconButton onClick={() => prevDocument()} disabled={docNum === 0} sx={{ ml: 'auto' }}><NavigateBeforeIcon /></IconButton>*/}
                                        {/*    </Tooltip>*/}
                                        {/*    <h3>{docNum + 1}/{numDocs}</h3>*/}
                                        {/*    <Tooltip title="Next Document">*/}
                                        {/*        <IconButton onClick={() => nextDocument()} disabled={docNum >= numDocs - 1}><NavigateNextIcon /></IconButton>*/}
                                        {/*    </Tooltip>*/}
                                        {/*    */}{/* TODO - add Box here with a 2VW width, separate out these controls  */}
                                        {/*    <Box sx={{ width: '24VW' }}></Box>*/}
                                        {/*    <Tooltip title="Get Documents from Next Family">*/}
                                        {/*        <Button variant="contained" onClick={() => fetchBatch()} disabled={getNextFamilyDisabled() ? true : false} sx={{ bgcolor: '#0059a8' }} sx={{ mr: '2rem' }}>*/}
                                        {/*            Next Family*/}
                                        {/*        </Button>*/}
                                        {/*    </Tooltip>*/}
                                        {/*</Box>*/}

                                        {/*<Box display="flex" alignItems="center" sx={{ width: 740, height: '5VH', bgcolor: '#f6be00' }}>*/}
                                        {/*    <IconButton sx={{ ml: 'auto' }}><NavigateBeforeIcon /></IconButton>*/}
                                        {/*    */}{/* <h3>{docNum + 1}/{numDocs}</h3>*/}
                                        {/*    <IconButton ><NavigateNextIcon /></IconButton>*/}
                                        {/*    <Button variant="contained" sx={{ bgcolor: '#0059a8' }} sx={{ mr: '2rem' }}>*/}
                                        {/*        Next Batch*/}
                                        {/*    </Button>*/}
                                        {/*</Box>*/}
                                        <Box sx={{ height: '90VH', bgcolor: '#eff6ff' }}>
                                            <Box display="flex" alignItems="top" sx={{ width: 100, mt: '10VH'}}>

                                                {/* CLOSE DRAWER BUTTON */}

                                                {/*   <IconButton onClick={() => closeDrawer()}><NavigateNextIcon /></IconButton>*/}


                                                {/* <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList value={value} onChange={handleTabChange}>
                                        <Tab label="Form" id="tabForm" value="1"/>
                                        <Tab label="Details" id="tabDetails" value="2"/>
                                    </TabList>
                                    <TabPanel value="1"><DocumentForm/></TabPanel>
                                    <TabPanel value="2">Details Go Here...</TabPanel>
                                </Box>
                                </TabContext> */}



                                                <DocumentForm
                                                    familyDetails={familyDetails}
                                                    employeeDetails={employeeDetails}
                                                    docDetails={docDetails}
                                                />
                                                {/*  <Divider sx={{ m: 5 }} orientation="vertical" variant="middle" flextItem />*/}
                                                {/*<Divider sx={{ m: 5 }} variant="middle" flextItem />*/}

                                                {/*<Box sx={{ width: '5VW', mt: '70VH' }}>*/}
                                                {/*    <Button variant="contained" onClick={() => fetchBatch()} disabled={getNextFamilyDisabled() ? true : false} sx={{ bgcolor: '#0059a8', width: '2.5rem', height: '1.5rem' }} sx={{ mr: '2rem' }}>*/}
                                                {/*        Next Family*/}
                                                {/*    </Button>*/}
                                                {/*</Box>*/}

                                                <FamilyDetails
                                                    familyDetails={familyDetails}
                                                    employeeDetails={employeeDetails}
                                                />


                                            </Box>

                                        </Box>
                                        {/*{isPdf ? (<>*/}
                                        <SmallToolbar />
                                       {/* </>) : (<></>) }*/}
                                    </Drawer>
                                </Box>
                                {/* FAB BUTTON */}

                                {/*<Fab sx={{ position: 'fixed', bottom: 50, right: 50 }} aria-label="edit menu" color='primary' onClick={() => openDrawer()}><EditIcon/></Fab>*/}
                            </>)

                            : (<></>)}
                    
                </Box>
                ) 
                : (<></>)
        }
        </>);
}


import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { EmployeeSearchContext } from '../../pages/EmployeeSearch';


import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';






function SearchFiltersMobile({ searchValueAuditID, searchValueClient, searchValueFirstName, searchValueLastName, searchValueEmailAddress, searchValueDOB, openUpdateModal }) {
    
    const {
        openMobile,
        handleDrawerCloseMobile,
        handleDrawerOpenMobile,
        handleUpdateEmployeeOnlySearch,
        employeeOnlySearch,
        handleUpdateSearchValueClientExactMatch,
        searchValueClientExactMatch,
        handleUpdateSearchFirstNameExactMatch,
        searchFirstNameExactMatch,
        handleUpdateSearchLastNameExactMatch,
        searchLastNameExactMatch,
        handleSearchValueAuditIDChange,
        handleSearchValueClientChange,
        handleSearchValueFirstNameChange,
        handleSearchValueLastNameChange,
        handleSearchValueEmailAddressChange,
        handleSearchValueDOBChange,
        handleGeneratePreviousMainSearchFilterModel,
        previousSearchParameters,
        handleClearSearchAndBackUpPreviousFilterModel,
        handleGenerateNewMainSearchFilterModel
    } = useContext(EmployeeSearchContext);

    const [openModal, setOpenModal] = React.useState(openMobile ? (openMobile) : (false));
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
       /* setUpdateUserModalOpen(false);*/
    };


    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };

    //react-hook-modal setup

    //const {
    //    register: registerUserModal,
    //    handleSubmit: handleSubmitUserModal,
    //    formState: { errors },
    //    watch: watchUserModal,
    //    reset: resetUserModal,
    //    control
    //} = useForm({
    //    defaultValues: {
    //        currentEmail: userDataUserEmail,
    //        email: userDataUserEmail ? (userDataUserEmail) : (""),
    //        givenName: userDataFirstName ? (userDataFirstName) : (""),
    //        surname: userDataLastName ? (userDataLastName) : (""),
    //        displayName: userDataUserName ? (userDataUserName) : (""),
    //        phoneNumber: userDataPhoneNumber ? (userDataPhoneNumber) : (""),
    //        accountEnabled: userDataUserStatus

    //    }
    //});

    //const watchAllUserModalFields = watchUserModal();

    //const onContentUserModalSubmit = (data) => {

    

    //    setHaveUserData(!haveUserData);
    //    resetUserModal();
    //    handleCloseModal();


    //};


    return (
        <div>

            <BootstrapDialog
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleDrawerCloseMobile}
                aria-labelledby="customized-dialog-title"
                open={openMobile}
            >
                <BootstrapDialogTitle sx={{ fontWeight: 700, fontSize: '20px' }} id="customized-dialog-title" onClose={handleDrawerCloseMobile}>
                    Search Filters
                </BootstrapDialogTitle>

                <DialogContent>
                    {/*//FORM CONTENT GOES HERE*/}

                    {/*<div className="modal-row">*/}
                    {/*    <Typography*/}

                    {/*        aria-label={'First Name'}*/}
                    {/*        sx={{ color: '#404040', fontSize: '14px', fontWeight: 600 }}>First Name</Typography>*/}

                    {/*</div>*/}

                    <Divider style={{ background: '#FFFFFF' }} />
                    <List>
                        <div className="row-no-left-margin">
                            <div className="row-left">
                                <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, ml: 3 }}> Search Filters</Typography>
                            </div>
                        </div>
                        {/* <Divider></Divider>*/}


                    </List>
                    <>

                        <>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{ ml: 1 }}
                                >
                                    Filter Options
                                </AccordionSummary>
                                <AccordionActions>
                                    <Button
                                        onClick={() => handleUpdateEmployeeOnlySearch(!employeeOnlySearch)}

                                        sx={{ bgcolor: '#ffffff', }}
                                    >
                                        Employee-Only Search
                                        <Switch
                                            checked={employeeOnlySearch}


                                        />

                                    </Button>
                                </AccordionActions>
                                <AccordionActions>
                                    <Button
                                        onClick={() => handleUpdateSearchValueClientExactMatch(!searchValueClientExactMatch)}
                                        sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}
                                    >
                                        Client Name Exact
                                        <Switch
                                            checked={searchValueClientExactMatch}


                                        />

                                    </Button>
                                </AccordionActions>
                                {/*<AccordionActions>*/}
                                {/*    <Button*/}
                                {/*                onClick={() => handleUpdateEmployeeOnlySearch(!employeeOnlySearch)}*/}

                                {/*                sx={{ bgcolor: '#ffffff', }}*/}
                                {/*            >*/}
                                {/*                Employee-Only Search*/}
                                {/*                <Switch*/}
                                {/*                    checked={employeeOnlySearch}*/}


                                {/*                />*/}

                                {/*            </Button>*/}
                                {/*</AccordionActions>*/}
                                {/*<AccordionActions>*/}
                                {/*            <Button*/}
                                {/*                onClick={() => handleUpdateSearchValueClientExactMatch(!searchValueClientExactMatch)}*/}
                                {/*                sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}*/}
                                {/*            >*/}
                                {/*                Client Name Exact*/}
                                {/*                <Switch*/}
                                {/*                    checked={searchValueClientExactMatch}*/}


                                {/*                />*/}

                                {/*            </Button>*/}
                                {/*</AccordionActions>*/}
                                <AccordionActions>
                                    <Button onClick={() => handleUpdateSearchFirstNameExactMatch(!searchFirstNameExactMatch)}
                                        sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}
                                    >
                                        First Name Exact
                                        <Switch
                                            checked={searchFirstNameExactMatch}


                                        />

                                    </Button>
                                </AccordionActions>
                                <AccordionActions>
                                    <Button onClick={() => handleUpdateSearchLastNameExactMatch(!searchLastNameExactMatch)}

                                        sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}>
                                        Last Name Exact
                                        <Switch
                                            checked={searchLastNameExactMatch}


                                        />

                                    </Button>
                                </AccordionActions>
                                {/*<AccordionDetails>*/}
                                {/*    <List*/}
                                {/*        style={{ marginTop: '0.5VH' }}*/}
                                {/*    >*/}
                                {/*        <Button*/}
                                {/*            onClick={() => handleUpdateEmployeeOnlySearch(!employeeOnlySearch)}*/}

                                {/*            sx={{ bgcolor: '#ffffff', }}*/}
                                {/*        >*/}
                                {/*            Employee-Only Search*/}
                                {/*            <Switch*/}
                                {/*                checked={employeeOnlySearch}*/}


                                {/*            />*/}

                                {/*        </Button>*/}
                                {/*    </List>*/}
                                {/*    <List*/}
                                {/*        style={{ marginTop: '0.5VH' }}*/}
                                {/*    >*/}
                                {/*        <Button*/}
                                {/*            onClick={() => handleUpdateSearchValueClientExactMatch(!searchValueClientExactMatch)}*/}
                                {/*            sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}*/}
                                {/*        >*/}
                                {/*            Client Name Exact*/}
                                {/*            <Switch*/}
                                {/*                checked={searchValueClientExactMatch}*/}


                                {/*            />*/}

                                {/*        </Button>*/}
                                {/*    </List>*/}
                                {/*    <List*/}
                                {/*        style={{ marginTop: '0.5VH' }}*/}
                                {/*    >*/}
                                {/*        <Button onClick={() => handleUpdateSearchFirstNameExactMatch(!searchFirstNameExactMatch)}*/}
                                {/*            sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}*/}
                                {/*        >*/}
                                {/*            First Name Exact*/}
                                {/*            <Switch*/}
                                {/*                checked={searchFirstNameExactMatch}*/}


                                {/*            />*/}

                                {/*        </Button>*/}
                                {/*    </List>*/}
                                {/*    <List*/}
                                {/*        style={{ marginTop: '0.5VH' }}*/}
                                {/*    >*/}
                                {/*        <Button onClick={() => handleUpdateSearchLastNameExactMatch(!searchLastNameExactMatch)}*/}

                                {/*            sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}>*/}
                                {/*            Last Name Exact*/}
                                {/*            <Switch*/}
                                {/*                checked={searchLastNameExactMatch}*/}


                                {/*            />*/}

                                {/*        </Button>*/}
                                {/*    </List>*/}
                                {/*</AccordionDetails>*/}
                                {/*<AccordionDetails>*/}


                                {/*</AccordionDetails>*/}
                            </Accordion>


                        </>

                    </>
                    <List
                        style={{ marginTop: '0.5VH' }}
                    >

                        {/* <CustomMobileInputAuditID value={searchValueAuditID} onChange={(e) => handleSearchValueAuditIDChange(e)} />*/}

                        <TextField

                            label="Audit ID"
                            variant="outlined"
                            value={searchValueAuditID}
                            onChange={handleSearchValueAuditIDChange}

                            sx={{ ml: 0, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}
                        />


                    </List>


                    <List
                        style={{ marginTop: '0.5VH' }}
                    >

                        <TextField
                            label="Client"
                            variant="outlined"
                            value={searchValueClient}
                            onChange={handleSearchValueClientChange}

                            sx={{ ml: 0, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                        />

                    </List>

                    <List
                        style={{ marginTop: '0.5VH' }}
                    >


                        <TextField
                            label="First Name"
                            variant="outlined"
                            value={searchValueFirstName}
                            onChange={handleSearchValueFirstNameChange}

                            sx={{ ml: 0, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                        />


                    </List>

                    <List
                        style={{ marginTop: '0.5VH' }}
                    >


                        <TextField
                            label="Last Name"
                            variant="outlined"
                            value={searchValueLastName}
                            onChange={handleSearchValueLastNameChange}

                            sx={{ ml: 0, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                        />


                    </List>

                    <List
                        style={{ marginTop: '0.5VH' }}
                    >


                        <TextField
                            label="Email Address"
                            variant="outlined"
                            value={searchValueEmailAddress}
                            onChange={handleSearchValueEmailAddressChange}

                            sx={{ ml: 0, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                        />


                    </List>

                    <List
                        style={{ marginTop: '0.5VH' }}
                    >


                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                                label="Date of Birth"
                                format="MM/DD/YYYY"
                                value={searchValueDOB}
                                onChange={(value) => handleSearchValueDOBChange(value)}
                                sx={{ ml: 0, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}

                            />
                        </LocalizationProvider>


                    </List>

                    <Button
                        variant="contained"
                        label="PREVIOUS SEARCH"
                        sx={{ ml: 0, mr: '1VW', width: '77VW', height: '8VH', bgcolor: '#3B82F6', mt: 3, }}
                        onClick={() => handleGeneratePreviousMainSearchFilterModel()} disabled={(previousSearchParameters.length === 0) ? true : false}
                    >
                        PREVIOUS SEARCH
                    </Button>

                    <Button
                        variant="contained"
                        label="CLEAR SEARCH"
                        sx={{ ml: 0, mr: '1VW', width: '77VW', height: '8VH', bgcolor: '#FFFFFF', color: '#000000', mt: 3, }}
                        onClick={() => handleClearSearchAndBackUpPreviousFilterModel()}
                    >
                        CLEAR SEARCH
                    </Button>


                    <Button
                        variant="contained"
                        label="GENERATE SEARCH"
                        sx={{ ml: 0, mr: '1VW', width: '77VW', height: '8VH', bgcolor: '#3B82F6', mt: 3, }}
                        onClick={() => handleGenerateNewMainSearchFilterModel()}
                    >
                        GENERATE SEARCH
                    </Button>

                    <Box sx={{ height: '2VH', mb: 6 }}></Box>

                </DialogContent>
                <DialogActions>


                </DialogActions>

            </BootstrapDialog>

        </div>
    );
}

export default SearchFiltersMobile;
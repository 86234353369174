
import React from 'react';

import Box from "@mui/material/Box";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import { Link } from "react-router-dom";
import Chip from '@mui/material/Chip';
import { format, formatISO } from 'date-fns'





function FamilyDetailsEmployeeCard({ employeeCardEmployeeFirstName, employeeCardEmployeeLastName, employeeCardEmployeeAuditFamilyStatus, employeeCardAuditFamilyId, employeeCardAuditEndDate, employeeCardEmailOpInValue, employeeCardEmployeeID, employeeCardEmployeeEmail, employeeCardEmployeeLocation, employeeCardEmployeePhoneNumber, employeeCardAddress, employeeCardMobileVariant, employeeCardNumberOfLogins, employeeCardLastLoginTimestamp }) {

    const cardTheme = createTheme({
        palette: {
            primary: {
                main: '#FFFFFF',
            },
        },
    });

    const StyledCard = styled(Card)`
        ${({ theme }) => `
        cursor: pointer,
        background-color:  '#FFFFFF';
        transition: ${theme.transitions.create(['background-color', 'transform'], {
        duration: theme.transitions.duration.standard,
    })};
        &:hover {
            background-color: '#FFFFFF';
            transform: scale(1.05) translateZ(0);
            backface-visibility: hidden;
            
        }
        `} 
    `;

    //from mui documentation

    const [cardSize, setCardSize] = React.useState("inherit");

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        if (name !== undefined) {

            return {
                sx: {
                    bgcolor: '#737373',
                    color: '#FFFFFF',
                },
                children: `${name.split(' ')[0][0]}`,
            };
        }
    }

    function renderDate(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'MM/dd/yyyy');
    }

    function renderMemberStatus(dataText) {
        if (dataText == "complete") {
            return (<Chip sx={{ bgcolor: '#BBF7D0', color: '#166534' }} label={'COMPLETE'} />)
        } else if (dataText == "Incomplete") {
            return (<Chip sx={{ bgcolor: '#FDE68A', color: '#92400E' }} label={'INCOMPLETE'} />)
        } else if (dataText == "Ineligible") {
            return (<Chip sx={{ bgcolor: '#FECACA', color: '#991B1B', }} label={'INELIGIBLE'} />)
        //} else if (dataText == "") {
        //    return (<Chip sx={{ bgcolor: '#FECACA', color: '#991B1B', }} label={'DB: NO STATUS'} />)
        } else {
            return (<Chip label={dataText} />)
        }
    }


    return (


        employeeCardMobileVariant == true ? (<>
            <Box sx={{ width: '100VW' }}>
                <div className="row-no-left-margin" style={{ width: "80.5VW" }}>
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="700" fontSize="1.5em" sx={{ mt: 0, mr: '1VW' }}>{employeeCardEmployeeFirstName}  {employeeCardEmployeeLastName}</Typography>

                   

                   {/* <Chip sx={{ bgcolor: '#FDE68A', color: '#92400E' }} label={employeeCardEmployeeAuditFamilyStatus} />*/}

                    {/*<div className="row-left">*/}


                    {/*    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="700" fontSize="1.5em" sx={{ mt: 0, }}>{employeeCardEmployeeFirstName}  {employeeCardEmployeeLastName}</Typography>*/}
                    {/*    <Chip sx={{ bgcolor: '#FDE68A', color: '#92400E' }} label={employeeCardEmployeeAuditFamilyStatus} />*/}
                    {/*</div>*/}
                    {/*<div className="row-right">*/}


                    {/*</div>*/}
                </div>
                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>
                    <Typography textAlign="left" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Audit Family ID  <Chip label={employeeCardAuditFamilyId} /> </Typography>
              



                </div>
                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>
                    <Typography textAlign="left" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Family Status    {renderMemberStatus(employeeCardEmployeeAuditFamilyStatus)} </Typography>




                </div>
              
                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>
          
                    <Typography textAlign="left" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Audit End Date  <Chip label={renderDate(employeeCardAuditEndDate)} /> </Typography>
              



                </div>

                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>
               
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, }}>Email Opt-In <Chip sx={{ bgcolor: employeeCardEmailOpInValue ? '#BBF7D0' : '#FECACA', color: employeeCardEmailOpInValue ? '#166534' : '#991B1B', }} label={employeeCardEmailOpInValue ? 'SELECTED' : 'NOT SELECTED'} /> </Typography>




                </div>

                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>

                    <Typography textAlign="left" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Number of Logins  <Chip label={employeeCardNumberOfLogins} /> </Typography>




                </div>

                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>

                    <Typography textAlign="left" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Last Login Date  <Chip label={renderDate(employeeCardLastLoginTimestamp)} /> </Typography>




                </div>

                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, }}>Email: {employeeCardEmployeeEmail}</Typography>


                </div>
                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, }}>Phone Number: {employeeCardEmployeePhoneNumber}</Typography>


                </div>
                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, }}>Location: {employeeCardEmployeeLocation}</Typography>


                </div>
            </Box>
        </>) : (<>
            <Box sx={{ width: '80VW' }}>
                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="700" fontSize="1.5em" sx={{ mt: 0, mr: '1VW' }}>{employeeCardEmployeeFirstName}  {employeeCardEmployeeLastName}</Typography>
                       {/* {renderMemberStatus(employeeCardEmployeeAuditFamilyStatus)}*/}

                    {/*  <Chip sx={{ bgcolor: '#FDE68A', color: '#92400E' }} label={employeeCardEmployeeAuditFamilyStatus} />*/}

                    {/*<div className="row-left">*/}


                    {/*    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="700" fontSize="1.5em" sx={{ mt: 0, }}>{employeeCardEmployeeFirstName}  {employeeCardEmployeeLastName}</Typography>*/}
                    {/*    <Chip sx={{ bgcolor: '#FDE68A', color: '#92400E' }} label={employeeCardEmployeeAuditFamilyStatus} />*/}
                    {/*</div>*/}
                    {/*<div className="row-right">*/}


                    {/*</div>*/}
                </div>
                <div className="row-no-left-margin" style={{ width: "88.5VW" }}>
                        <Typography textAlign="left" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Audit Family ID  <Chip label={employeeCardAuditFamilyId} /> </Typography>
                        <Typography textAlign="left" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Family Status   {renderMemberStatus(employeeCardEmployeeAuditFamilyStatus)} </Typography>
                        <Typography textAlign="left" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Audit End Date  <Chip label={renderDate(employeeCardAuditEndDate)} /> </Typography>
                


                    </div>

                    <div className="row-no-left-margin" style={{ width: "88.5VW" }}>
                        <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Email Opt-In <Chip sx={{ bgcolor: employeeCardEmailOpInValue ? '#BBF7D0' : '#FECACA', color: employeeCardEmailOpInValue ? '#166534' : '#991B1B', }} label={employeeCardEmailOpInValue ? 'SELECTED' : 'NOT SELECTED'} /> </Typography>
                        <Typography textAlign="left" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Number of Logins  <Chip label={employeeCardNumberOfLogins} /> </Typography>
                        <Typography textAlign="left" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, mr: '1VW' }}>Last Login Date  <Chip label={renderDate(employeeCardLastLoginTimestamp)} /> </Typography>



                    </div>

                <div className="row-no-left-margin" style={{ width: "42.5VW" }}>
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, }}>Email: {employeeCardEmployeeEmail}</Typography>


                </div>
                <div className="row-no-left-margin" style={{ width: "42.5VW" }}>
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, }}>Phone Number: {employeeCardEmployeePhoneNumber}</Typography>


                </div>
                <div className="row-no-left-margin" style={{ width: "42.5VW" }}>
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="400" fontSize="1em" sx={{ mt: 0, }}>Location: {employeeCardEmployeeLocation}</Typography>


                </div>
            </Box>
        </>))

        
           
       
}

export default FamilyDetailsEmployeeCard;


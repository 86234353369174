//anonomous layout if user is not logged in
import { createContext, useState, useEffect, useContext, useRef } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import NavigationBar from './navigation/NavigationBar';
import Box from '@mui/material/Box';
//import { useAuth } from "../components/auth/Auth";
import { EventType, InteractionType, InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { loginRequest, b2cPolicies } from "../authConfig";
import { PermissionsContext } from "../App.js";
import { SidebarContext } from "../App.js";
import { RolesContext } from "../App.js";


import { BsBoxArrowLeft, BsFillBellFill, BsFillBarChartFill, BsCheck2Square, BsPersonFill, BsPeople, BsGearFill, BsGear, BsHouse, BsHouseFill, BsCardChecklist } from "react-icons/bs";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Container from "@mui/material/Container";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AnonSidebar from "../layouts/AnonSidebar";
import AnonSidebarMobile from "../layouts/AnonSidebarMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";


export default function AnonLayout({ children }) {
    //const { user } = useAuth();

    const outlet = useOutlet();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { sidebarActiveUser, setSidebarActiveUser, setSidebarUserRole } = useContext(SidebarContext);
    const theme = useTheme();
    const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));
    const { roles, setRoles } = useContext(RolesContext);
   
    

    //console.log(permissions[0]?.isClient)
    //console.log(`report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`)
    return (<>
        {isNotMobile ? (
        <Box maxHeight={false} sx={{ bgcolor: '#FAFAFA', mt: 4, height: '100VH', width: '100VW', display: { xs: "none", md: "flex", xl: "flex" } }}>
                    <AnonSidebar/>
            {outlet}
            {children}
        </Box>
        ) : (
        <Container sx={{ display: { xs: "block", md: "none" }, backgroundColor: '#FAFAFA' }}>
            <div>
                <AnonSidebarMobile/>
                {outlet}
                <Box sx={{ backgroundColor: '#F6F7F8;', mt: 10, ml: 2, display: 'flex', width: '100VW' }}>


                    {children}

                </Box>
            </div>
            </Container>
        )}
        </>
       
    )

}


import { LicenseInfo } from '@mui/x-license';

import { createContext, useState, useEffect, ReactNode, useContext, useMemo, useCallback, lazy, useReducer } from "react";
import { Route, Routes, Navigate, Outlet, useNavigate, useLocation, Link, BrowserRouter } from 'react-router-dom';
import './custom.css';
import './css/app.css';
import './css/effects.css';
import Home from "./pages/Home";
import '../src/App.css'
import Counter2 from "./pages/Counter2";
import Report from "./pages/Report";
import NotFound from "./pages/NotFound";
import EmployeeSearch from "./pages/EmployeeSearch";
import GlobalEmployeeSearch from './pages/GlobalEmployeeSearch';
import EmployeeSearchLocal from "./pages/EmployeeSearchLocal";

import FamilyDetailsClient from "./pages/FamilyDetailsClient";
import FamilyDetailsGlobal from './pages/FamilyDetailsGlobal';


import AnonLayout from "./layouts/AnonLayout";
import Viewer from "./pages/Viewer";
import PageLayout from "./layouts/PageLayout";
import { ToastContainer, toast } from 'react-toastify';
import Profile from "./components/Profile";
import Settings from "./components/Settings";
import Notifications from "./features/Notifications";

import { MsalProvider, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated} from "@azure/msal-react";
import { EventType, InteractionType, InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { b2cPolicies, loginRequest, protectedResources } from "./authConfig";
import AuthGuard from "./features/auth/AuthGuard";

import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';

import Project from "./pages/Project";

import { useIdleTimer } from 'react-idle-timer';

//new font themeing

import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import GraphDetails from './pages/GraphDetails';



export const SidebarContext = createContext()
export const RoutesContext = createContext()
export const RolesContext = createContext()
export const PermissionsContext = createContext()
export const DocViewerContext = createContext()
export const BatchContext = createContext()
export const PersistentPreviousSearchContext = createContext()
export const GraphDetailsContext = createContext()

export const fontTheme = createTheme({
    typography: {
        fontFamily: ['Mulish',].join(','),

    }
});



export default function App({ instance }) {

    LicenseInfo.setLicenseKey('f3e6b9657f0b3c182d5083b563441641Tz05MTExNyxFPTE3NDgwMTg1NjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

    const [sidebarTitle, setSidebarTitle] = useState("BMI Eligibility Dashboard");

    const [sidebarActions, setSidebarActions] = useState([]);
    const [sidebarUserRole, setSidebarUserRole] = useState('');
    //const SidebarContextValues = useMemo(() => ({
    //    sidebarTitle,
    //    setSidebarTitle,
    //    sidebarActions,
    //    setSidebarActions,
    //    sidebarUserRole,
    //    setSidebarUserRole
    //}), [
    //    sidebarTitle,
    //    setSidebarTitle,
    //    sidebarActions,
    //    setSidebarActions,
    //    sidebarUserRole,
    //    setSidebarUserRole
    //])


    const [sidebarCurrentUserTheme, setSidebarCurrentUserTheme] = useState('#A3A3A3');
    const [sidebarActiveUser, setSidebarActiveUser] = useState([]);
    const [sidebarAccountMenuAnchor, setSidebarAccountMenuAnchor] = useState(null)
    const [switchActiveClientData, setSwitchActiveClientData] = useState([]);
    const [clientSearchData, setClientSearchData] = useState([]);
    const [sidebarDataHasUpdate, setSidebarDataHasUpdate] = useState(false);
    const [sidebarActiveClientID, setSidebarActiveClientID] = useState(null);
    const [sidebarActiveProduct, setSidebarActiveProduct] = useState(null);

    const [sidebarLogoutMode, setSidebarLogoutMode] = useState(false);
    const [sidebarAvatarInitials, setSidebarAvatarInitials] = useState(" ");

    //inactivity tracking
    const [inactivityState, setInactivityState] = useState('Active');
  

    const onIdle = () => {
        //redirect logout
        setInactivityState('Idle')
        instance.logoutRedirect();
    }

    const onActive = () => {
        //set active
        setInactivityState('Active')
    }

   

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        timeout: 900000, //timeout in 15 minutes
        throttle: 500
    });
    function getUserThemeColor() {
        //const rng = Math.floor(Math.random() * 3);
        let newBg = '#23549F';
        //let newBg = '#737373';
        //switch (rng) {
        //    case 0:
        //        newBg = '#737373';
        //        break;
        //    case 1:
        //        newBg = '#EDC041';
        //        break;
        //    case 2:
        //        newBg = '#737373';
        //        break;
        //    default:
        //        break;

        //}

        setSidebarCurrentUserTheme(newBg);
    }

  

    //getUserThemeColor();
    

    const SidebarContextValues = useMemo(() => ({
        sidebarTitle,
        setSidebarTitle,
        sidebarActions,
        setSidebarActions,
        sidebarUserRole,
        setSidebarUserRole,
        sidebarAccountMenuAnchor,
        setSidebarAccountMenuAnchor,
        switchActiveClientData,
        setSwitchActiveClientData,
        clientSearchData,
        setClientSearchData,
        sidebarDataHasUpdate,
        setSidebarDataHasUpdate,
        sidebarActiveClientID,
        setSidebarActiveClientID,
        sidebarActiveUser,
        setSidebarActiveUser,
        sidebarCurrentUserTheme,
        setSidebarCurrentUserTheme,
        sidebarLogoutMode, setSidebarLogoutMode,
        sidebarAvatarInitials, setSidebarAvatarInitials,
        sidebarActiveProduct, setSidebarActiveProduct
    }), [
        sidebarTitle,
        setSidebarTitle,
        sidebarActions,
        setSidebarActions,
        sidebarUserRole,
        setSidebarUserRole,
        sidebarAccountMenuAnchor,
        setSidebarAccountMenuAnchor,
        switchActiveClientData,
        setSwitchActiveClientData,
        clientSearchData,
        setClientSearchData,
        sidebarDataHasUpdate,
        setSidebarDataHasUpdate,
        sidebarActiveClientID,
        setSidebarActiveClientID,
        sidebarActiveUser,
        setSidebarActiveUser,
        sidebarCurrentUserTheme,
        setSidebarCurrentUserTheme,
        sidebarLogoutMode, setSidebarLogoutMode,
        sidebarAvatarInitials, setSidebarAvatarInitials,
        sidebarActiveProduct, setSidebarActiveProduct
    ])

    ////

    const [userPersistentPreviousSearchLocal, setUserPersistentPreviousSearchLocal] = useState([]);

    function handleUpdatePersistentPreviousSearchParameters(updates) {
        console.log("Hit-handleUpdatePersistentPreviousSearchParameters, Updates:", updates);
        setUserPersistentPreviousSearchLocal(updates)
    }


    const [userPersistentPreviousSearchLocalLogicalOperator, setUserPersistentPreviousSearchLocalLogicalOperator] = useState("");

    function handleUpdatePersistentPreviousSearchLogicalOperator(updates) {
        console.log("Hit-handleUpdatePersistentPreviousSearchLogicalOperator, Updates:", updates);
        setUserPersistentPreviousSearchLocalLogicalOperator(updates)
    }


    const [userPersistentPreviousSearchGlobal, setUserPersistentPreviousSearchGlobal] = useState([]);

    function handleUpdatePersistentPreviousSearchParametersGlobal(updates) {
        console.log("Hit-handleUpdatePersistentPreviousSearchParametersGlobal, Updates:", updates);
        setUserPersistentPreviousSearchGlobal(updates)
    }

    const [userPrintablePersistentPreviousGlobalString, setUserPrintablePersistentPreviousGlobalString] = useState([]);

    

    function handleUpdatePrintablePersistentPreviousGlobalString(updates) {
        console.log("Hit- handleUpdatePrintablePersistentPreviousGlobalString, Updates:", updates);
        setUserPrintablePersistentPreviousGlobalString(updates)
    }



    const PreviousSearchContentValue = useMemo(() => ({
        userPersistentPreviousSearchLocal,
        setUserPersistentPreviousSearchLocal,
        handleUpdatePersistentPreviousSearchParameters,
        userPersistentPreviousSearchLocalLogicalOperator,
        setUserPersistentPreviousSearchLocalLogicalOperator,
        handleUpdatePersistentPreviousSearchLogicalOperator,
        userPersistentPreviousSearchGlobal,
        setUserPersistentPreviousSearchGlobal,
        handleUpdatePersistentPreviousSearchParametersGlobal,
        userPrintablePersistentPreviousGlobalString,
        setUserPrintablePersistentPreviousGlobalString,
        handleUpdatePrintablePersistentPreviousGlobalString
    }), [
        userPersistentPreviousSearchLocal,
        setUserPersistentPreviousSearchLocal,
        handleUpdatePersistentPreviousSearchParameters,
        userPersistentPreviousSearchLocalLogicalOperator,
        setUserPersistentPreviousSearchLocalLogicalOperator,
        handleUpdatePersistentPreviousSearchLogicalOperator,
        userPersistentPreviousSearchGlobal,
        setUserPersistentPreviousSearchGlobal,
        handleUpdatePersistentPreviousSearchParametersGlobal,
        userPrintablePersistentPreviousGlobalString,
        setUserPrintablePersistentPreviousGlobalString,
        handleUpdatePrintablePersistentPreviousGlobalString

    ])


    const isAuthenticated = useIsAuthenticated();
    //const { instance } = useMsal();

    //{
    //    isAuthenticated ? ( return (
    //        <Routes>
    //            <Route path="/admin-home" element={<AdminLayout />}>
    //            <Route path="report" element={<Report />} />
    //            <Route path="counter2" element={<Counter2 />} />
    //            <Route path="profile" element={<Profile />} />
    //        </Route>
    //        </Routes>

    //                                );
    //    ) : ()



    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                                account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
                        );
                    //instance.setActiveAccount(originalSignInAccount);
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };

                    // silently login again with the signUpSignIn policy
                    instance.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.passwordReset) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: [
                            ...protectedResources.api.scopes,
                        ],
                    };
                    //instance.loginRedirect(loginRequest).then((response) => {
                    //    instance.setActiveAccount(response.account);
                    ////});
                    instance.logoutRedirect();
                    //instance.loginRedirect(signUpSignInFlowRequest);
                }

                if (event.payload.idTokenClaims['acr'] === b2cPolicies.names.finishRegistration) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: [
                            ...protectedResources.api.scopes,
                        ],
                    };
                    instance.loginRedirect(signUpSignInFlowRequest);
                }
                
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.passwordReset.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                    //instance.logoutRedirect();
                }
            }
        });

        getUserThemeColor();

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    //used to maintain accurate route state in children components throughout the app (i.e. side bar)
    const [newRoutes, setRoutes] = useState(
        [
            {
                id: 0,
                label: "Home",
                path: "report",
                icon: <HomeIcon />,
                isActive: true,
                style: 'active-navLink border-bottom__thick-blue',
                isNested: false
            }
        ]);

    const [roles, setRoles] = useState("");
    const [permissions, setPermissions] = useState(
        [
            {
                isClient: null,
                auditId: 0,
                auditName: ""

            }
        ]);

    const [documentListState, setDocumentListState] = useState(null);
    const [batchCount, setBatchCount] = useState(0);
    const [batchFamilyId, setBatchFamilyId] = useState(0) 

    const docListValues = useMemo(() => ({
        documentListState,
        setDocumentListState,
        batchCount, setBatchCount, batchFamilyId, setBatchFamilyId
    }), [documentListState, setDocumentListState,
        batchCount, setBatchCount, batchFamilyId, setBatchFamilyId
    ]);

    function handleUpdateDocumentDependentProgressIndicator(memberID, newStatus) {
        console.log("MemberID:", memberID, "newStatus:", newStatus);
        const newDocumentDependentProgressData = [...documentDependentProgress];
        let foundItem = null;

        const newMap = newDocumentDependentProgressData.map((item) => {
            if (item?.memberId == memberID) {
                console.log("FoundMemberID:", item);
                foundItem = item;
            }
        })
        foundItem.indicatorStatus = newStatus;
        setDocumentDependentProgress(newDocumentDependentProgressData);
    }

    function handleProvideDocumentProcessStatusByDependentID(memberIDToCheck) {
        console.log("MemberIDToCheck:", memberIDToCheck);
        const newDocumentDependentProgressData = [...documentDependentProgress];
        let foundItem = 0;

        const newMap = newDocumentDependentProgressData.map((item) => {
            if (item?.memberId == memberIDToCheck) {
                console.log("Found MemIDToCheck! Heres its item indicator status:", item.indicatorStatus);
                foundItem = item.indicatorStatus;

            }

        })
        return foundItem;

    }



    const [documentDependentProgress, setDocumentDependentProgress] = useState([]); 
    const [docNum, setDocNum] = useState(0);
    const [numDocs, setNumDocs] = useState(0);
    const [batch, setBatch] = useState([]);

    function nextDocument() {

        setDocNum(docNum + 1);

    }

    function prevDocument() {

        setDocNum(docNum - 1);

    }

    //async function fetchBatch() {
    //    await fetch(process.env.REACT_APP_BACK_URL + '/api/Processing/FetchNextFamily',
    //        {
    //            method: 'GET',
    //            mode: 'cors',
    //        })
    //        .then(res => res.json())
    //        .then(
    //            (response) => {
    //                setBatch([response]);
    //                setDocumentListState([]);
    //                setBatchCount(0);
    //                setBatchFamilyId(response?.family?.familyId);
    //                console.log("response - ", response);
    //            }

    //        ).catch((error) => { console.log(error) });

    //}

    async function fetchBatch() {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
            await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                .then(result => {
                    const fetchDataForBatch = async () => {
                        await fetch(process.env.REACT_APP_BACK_URL + '/api/Processing/FetchNextFamily',
                            {
                                method: 'GET',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                            })
                            .then(res => res.json())
                            .then(
                                (response) => {
                                    setBatch([response]);
                                    setDocumentListState([]);
                                    setBatchCount(0);
                                    setBatchFamilyId(response?.family?.familyId);
                                    console.log("response - ", response);
                                }

                            ).catch((error) => { console.log(error) });
                    }
                    fetchDataForBatch();
                }).catch((error) => {
                    console.log(error) //token expired/is not valid => user is redirected to login page
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }
                });
        }
    }

    const [documentTypes, setDocumentTypes] = useState([]);
    const [relationshipTypes, setRelationshipTypes] = useState([]);
    const [isDocSwitchDisabled, setIsDocSwitchDisabled] = useState(false);

    const DocumentViewerContextValues = useMemo(() => ({
        docNum, numDocs, prevDocument, nextDocument, fetchBatch,
        documentDependentProgress, setDocumentDependentProgress,
        handleUpdateDocumentDependentProgressIndicator,
        handleProvideDocumentProcessStatusByDependentID,
        batch, setBatch, setDocNum, setNumDocs, documentTypes, setDocumentTypes, relationshipTypes, setRelationshipTypes,
        isDocSwitchDisabled, setIsDocSwitchDisabled
    }), [
        docNum, numDocs, prevDocument, nextDocument, fetchBatch,
        documentDependentProgress, setDocumentDependentProgress,
        handleUpdateDocumentDependentProgressIndicator,
        handleProvideDocumentProcessStatusByDependentID,
        batch, setBatch, setDocNum, setNumDocs, documentTypes, setDocumentTypes, relationshipTypes, setRelationshipTypes,
        isDocSwitchDisabled, setIsDocSwitchDisabled
    ])

    const [graphComponentData, setGraphComponentData] = useState([])

    function handleUpdateGraphComponentData(updates) {
        console.log("Hit-handleUpdateGraphComponentData, Updates:", updates);
        setGraphComponentData(updates)
    }

    const [graphTableComponentData, setGraphTableComponentData] = useState([])

    function handleUpdateGraphTableComponentData(updates) {
        console.log("Hit-handleUpdateGraphTableComponentData, Updates:", updates);
        setGraphTableComponentData(updates)
    }

    const [openMobileGraphDetails, setOpenMobileGraphDetails] = useState(false);

    const handleDrawerOpenMobileGraphDetails = () => {
        setOpenMobileGraphDetails(true);
    };

    const handleDrawerCloseMobileGraphDetails = () => {
        setOpenMobileGraphDetails(false);
    };


    const GraphDetailsContextValue = useMemo(() => ({
        graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData,
        openMobileGraphDetails,
        handleDrawerOpenMobileGraphDetails,
        handleDrawerCloseMobileGraphDetails
    }), [
        graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData,
        openMobileGraphDetails,
        handleDrawerOpenMobileGraphDetails,
        handleDrawerCloseMobileGraphDetails
    ])

    

    const routing = useMemo(() => ({ newRoutes, setRoutes }), [newRoutes, setRoutes]);
    const userRole = useMemo(() => ({ roles, setRoles }), [roles, setRoles]);
    const userPerms = useMemo(() => ({ permissions, setPermissions }), [permissions, setPermissions]);

    //const onChangeRoutes = useCallback((e) => {
    //    setRoutes(e);
    //}, []);


   

    //pass user instance and feature associated with route to check user access
    return (
        <ThemeProvider theme={fontTheme}>      
            <MsalProvider instance={instance}>
                <RolesContext.Provider value={userRole}>
                <PermissionsContext.Provider value={userPerms}>
                <RoutesContext.Provider value={routing}>
                <DocViewerContext.Provider value={DocumentViewerContextValues}>
                <SidebarContext.Provider value={SidebarContextValues}>
                <BatchContext.Provider value={docListValues}>
                <PersistentPreviousSearchContext.Provider value={PreviousSearchContentValue}>
                                            <GraphDetailsContext.Provider value={GraphDetailsContextValue}>
                <Routes>
               
                     <Route element={<PageLayout />}>
                        <Route path="report">
                            <Route index element={ 
                            <AuthGuard feature={"DASHBOARD"}>
                                        <Report/>
                            </AuthGuard>}/>
                            <Route path=":id/:auditName" element={
                                <AuthGuard feature={"PROJECT"}>
                                        <Project />
                                </AuthGuard>}>
                            </Route>
                                                        <Route path=":id/:auditName/graphDetails" element={
                                                            <AuthGuard feature={"GRAPHDETAILS"}>
                                                                <GraphDetails />
                                                            </AuthGuard>}>
                                                        </Route>
                            <Route path=":id/:auditName/users" element={
                                <AuthGuard feature={"USERMGMT"}>
                                        <Counter2 />
                                </AuthGuard>}>
                            </Route>
                              <Route path=":id/:auditName/search" element={
                                <AuthGuard feature={"EMPLOYEESEARCH"}>   
                                <EmployeeSearchLocal />
                                </AuthGuard>}>
                              </Route>
                                                  
                             <Route path=":id/:auditName/search/:auditFamilyId/:employeeId" element={
                                <AuthGuard feature={"FAMILYDETAILSCLIENT"}>
                                                           
                                                            <FamilyDetailsClient/>
                                </AuthGuard>}>
                            </Route>
                            
                        </Route>
                        <Route path="processing" element={
                            <AuthGuard feature={"PROCESSING"}>
                                    <Viewer/>
                            </AuthGuard>
                            }>
                        </Route>
                        <Route path="profile" element={
                            <AuthGuard feature={"PROFILE"}>
                                <Profile />
                            </AuthGuard>}>
                                                </Route>
                                                <Route path="globalsearch" element={
                                                    <AuthGuard feature={"GLOBALSEARCH"}>
                                                        <GlobalEmployeeSearch />
                                                    </AuthGuard>}>
                                                </Route>
                                                <Route path="globalsearch/:auditFamilyId/:employeeId" element={
                                                    <AuthGuard feature={"FAMILYDETAILSGLOBAL"}>

                                                        <FamilyDetailsGlobal />
                                                    </AuthGuard>}>
                                                </Route>
                        <Route path="settings" element={
                            <AuthGuard  feature={"SETTINGS"}>
                                <Settings />
                            </AuthGuard>}>
                        </Route>
                        {/*<Route path="/" element={<Home />} />*/}
                    </Route>
                    <Route element={<AnonLayout />}>
                        <Route path="/" element={<Home />} />
                     </Route>
                    <Route path="*" element={<NotFound />} />
                                                </Routes>
                 </GraphDetailsContext.Provider>
                </PersistentPreviousSearchContext.Provider>
                </BatchContext.Provider>
                </SidebarContext.Provider>
                </DocViewerContext.Provider>
                </RoutesContext.Provider>
                </PermissionsContext.Provider>
                </RolesContext.Provider>
            </MsalProvider>
        </ThemeProvider>
    );

    //routes are determined by roles and user status (logged in/out)
    //anonroutes => anonlayout
    //clientroutes => clientlayout
    //adminroutes => adminlayout

}


import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';

function ProjectTable({ tableData, desiredWidth, desiredHeight }) {

    if (desiredWidth == undefined || desiredWidth == null) {

    }

    //from mui documentation

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        if (name !== undefined) {
           
            return {
                sx: {
                    //bgcolor: '#737373',
                    bgcolor: '#23549F',
                    color: '#FFFFFF',
                    fontSize: 12
                },
                children: `${name.substring(0,3)}`,
            };
        }
    }


    const rows = tableData;
    const navigate = useNavigate();

    const columns = [
     
        {
            field: 'clientName', headerName: 'Clients', width: 400, renderCell: (cellValues) => {
                return ( 
                    <>
                        <div className="spacer-margin-right">
                            <Avatar sx={{ mr: '1rem' }}   {...stringAvatar(cellValues?.row?.auditName)} />
                        </div>

                        <div>
                            <Typography sx={{ color: '#2563EB', fontWeight: 700, fontSize: '16px', }}>{cellValues?.row?.clientName}</Typography>
                        </div>
                    </>
                );
            }
        },

    ];

    const handleNavigation = (params) => {
        
       /* console.log(params);*/
       
        navigate(`/report/${String(params.row.auditId)}/${params.row.auditName}`);

    };

    return (
        <>
            {desiredWidth ? (<>
                <div style={{  width: desiredWidth, height: desiredHeight, }}>
                    <DataGrid
                       
                        disableSelectionOnClick={true}
                        disableMultipleRowSelection={true}
                        getRowId={(row) => row.auditId}
                        rows={rows}
                        columns={columns}
                        onRowClick={(row) => handleNavigation(row)}
                        sx={{
                            cursor: 'pointer',
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                                border: 'none',
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold !important',
                                overflow: 'visible !important'
                            }
                        }}
                    />
                </div>
            </>) : (<>
                    <div style={{ height: 500, width: "70VW" }}>
                    <DataGrid
                       
                        disableSelectionOnClick={true}
                        disableMultipleRowSelection={true}
                        getRowId={(row) => row.auditId}
                        rows={rows}
                        columns={columns}
                            onRowClick={(row) => handleNavigation(row)}
                            sx={{
                                cursor: 'pointer',
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none',
                                },
                                '.MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 'bold !important',
                                    overflow: 'visible !important'
                                }
                            }}
                    />
                </div>
            </>)}
        </>
    );
}

export default ProjectTable;



import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1A_RemoteProfile_signup_signin",
        editProfile: "B2C_1A_RemoteProfile_ProfileEdit",
        passwordReset: "B2C_1A_RemoteProfile_PasswordReset",
        finishRegistration: "b2c_1a_inv_redeem"
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_B2C_SIGNUP_SIGNIN,
        },

        editProfile: {
            authority: process.env.REACT_APP_B2C_EDIT_PROFILE,
        },

        passwordReset: {
            authority: process.env.REACT_APP_B2C_RESET_PASSWORD
        },

        finishRegistration: {
            authority: process.env.REACT_APP_B2C_FISIHED_REGISTRATION
        },
    },
    authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN,

};


export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_LOOPBACK_URL_TLS + "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if(containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            piiLogginEnabled: false
        }
    }
};

export const protectedResources = {
    api: {
        endpoint: process.env.REACT_APP_FRONT_URL + "/api/report",//REACT_APP_FRONT_URL
        scopes: ["https://" + process.env.REACT_APP_B2C_CLIENTNAME + ".onmicrosoft.com/susi/user_impersonation"],
       
    }
}

export const loginRequest = {
    scopes: ["https://" + process.env.REACT_APP_B2C_CLIENTNAME + ".onmicrosoft.com/susi/user_impersonation", "openid", "offline_access"]

}

//export const apiRequest = {
//    scopes: [...protectedResources.backendApi.scopes, "openid", "offline_access"]
//}

export const pageRoutes = {
    
}

export const accessControl = {
    products: {
        CLAIMS: {
            features: [
                {
                    featureId: "PROJECT",
                    roles: [
                        {
                            permissions: ['GET', 'POST', 'PUT', 'DELETE'],
                            roleIds: ['ADMIN', 'PROJMGR', 'DELIVERY']
                        },
                        {
                            permissions: ['GET', 'POST', 'PUT'],
                            roleIds: ['AUDITOR']
                        },
                        {
                            permissions: ['GET'],
                            roleIds: ['CLIENT', 'BROKER', 'SALES']
                        },
                    ]
                },
                {
                    featureId: "CLAIMS_DASHBOARD",
                    roles: [
                        {
                            permissions: ['GET', 'POST', 'PUT', 'DELETE'],
                            roleIds: ['PROJMGR', 'ADMIN', 'DELIVERY']
                        },
                        {
                            permissions: ['GET', 'POST', 'PUT'],
                            roleIds: ['AUDITOR']
                        },
                        {
                            permissions: ['GET'],
                            roleIds: ['CLIENT', 'BROKER', 'SALES']
                        },
                    ]
                },
            ]
        },
        DEA: {
            features: [
                {
                    featureId: "PROJECT",
                    roles: [
                        {
                            permissions: ['GET', 'POST', 'PUT', 'DELETE'],
                            roleIds: ['ADMIN', 'PROJMGR']
                        },
                        {
                            permissions: ['GET', 'POST', 'PUT'],
                            roleIds: []
                        },
                        {
                            permissions: ['GET'],
                            roleIds: ['CLIENT', 'BROKER']
                        },
                    ]
                },
                {
                    featureId: "DEA_DASHBOARD",
                    roles: [
                        {
                            permissions: ['GET', 'POST', 'PUT', 'DELETE'],
                            roleIds: ['PROJMGR', 'ADMIN']
                        },
                        {
                            permissions: ['GET', 'POST', 'PUT'],
                            roleIds: ['AUDITOR']
                        },
                        {
                            permissions: ['GET'],
                            roleIds: ['CLIENT', 'BROKER', 'SALES']
                        },
                    ]
                },

            ]
        },
        PLAT: {
            features: [
                {
                    featureId: "CLIENTSETUP",
                    roles: [
                        {
                            permissions: ['GET', 'POST', 'PUT', 'DELETE'],
                            roleIds: ['ADMIN', 'PROJMGR']
                        },
                        {
                            permissions: ['GET', 'POST', 'PUT'],
                            roleIds: []
                        }, 
                        {
                            permissions: ['GET'],
                            roleIds: []
                        },
                    ]
                },
                {
                    featureId: "DASHBOARD",
                    roles: [
                        {
                            permissions: ['GET', 'POST', 'PUT'],
                            roleIds: ['CLIENT', 'BROKER', 'SALES', 'PROJMGR', 'ADMIN', 'DELIVERY', 'AUDITOR', 'ALL']
                        },
                    ]
                },
                {
                    featureId: "PROFILE",
                    roles: [
                        {
                            permissions: ['GET', 'POST', 'PUT'],
                            roleIds: ['CLIENT', 'BROKER', 'SALES', 'PROJMGR', 'ADMIN', 'DELIVERY', 'AUDITOR', 'ALL']
                        }
                    ]
                },
            ]
        },
    },

}
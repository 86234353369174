import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import AuthProvider from "./components/auth/Auth";
import reportWebVitals from './reportWebVitals';
/*import 'bootstrap/dist/css/bootstrap.css';*/
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";



const msalInstance = new PublicClientApplication(msalConfig);
//msalInstance["browserStorage"].clear();
//if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);

//}

//msalInstance.addEventCallback((event) => {
//    if (
//        (event.eventType === EventType.LOGIN_SUCCESS ||
//            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
//            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
//        event.payload.account
//    ) {
//        msalInstance.setActiveAccount(event.payload.account);
//    }

//});

//supress logs in production for frontend
if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
    console.warn = () => { }
    console.info = () => { }
}


const root = ReactDOM.createRoot(document.getElementById('root') || document.createElement('div'));


root.render(
    /*<React.StrictMode>*/
        <BrowserRouter>
            <App instance={ msalInstance } />
        </BrowserRouter>
    /*</React.StrictMode>*/
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect, useContext, createContext } from 'react'
import { useTable } from 'react-table'
import { useParams, useNavigate, Navigate, Link } from "react-router-dom";
import { useForm } from 'react-hook-form'
/*import { Artboard, Card, Collapse, Stats, Table, Progress, RadialProgress, Button, Input, Select, Modal, Dropdown } from 'react-daisyui';*/
import { BsCheckLg, BsXLg, BsExclamationCircleFill, BsThreeDots } from 'react-icons/bs';
/*import { SidebarContext } from "../App";*/

import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import bmilogoTextOnly from '../assets/BMI-logo-transparent.png';




function NotFound() {





    return (
        <>
            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 0, ml: '0', display: { xs: 'none', md: 'block', xl: 'block' } }} >
                <Container sx={{ mt: 10, display: { xs: 'none', md: 'block' } }}>
                    <Box
                        component="img"
                        sx={{
                            height: 84,
                            width: 190,
                            ml: 'auto',
                            mr: 'auto',
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alt="BMI"
                        src={bmilogoTextOnly}
                    />
                </Container >
                <Container sx={{ mt: '3rem', display: { xs: 'none', md: 'block' } }}>
                    <Typography textAlign="center" gutterBottom="true" fontWeight="600" fontSize="14px" sx={{ mt: 0, color: '#60A5FA' }}>404 Error</Typography>
                </Container >

                {/*  DESKTOP PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: '-1rem', display: { xs: 'none', md: 'block' } }}>
                    <Typography textAlign="center" gutterBottom="true" fontWeight="800" fontSize="48px" sx={{ mt: 0, color: '#262626' }}>This page does not exist.</Typography>
                </Container >

                <Container sx={{ mt: '-1rem', display: { xs: 'none', md: 'block' } }}>
                    <Typography textAlign="center" gutterBottom="true" fontWeight="400" fontSize="18px" sx={{ mt: 0, color: '#737373' }}>The page you are looking for could not be found</Typography>
                </Container >
                <Container sx={{ mt: 5.5, height: '4rem', display: { xs: 'none', md: 'block' } }}>
                    <div className="row-no-left-margin">
                        <div className="center">
                            <Link key="1" to="/"
                                style={{ textDecoration: 'none' }}
                            >
                                Or go back to home
                            </Link>
                        </div>
                    </div>
                </Container>

                <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>
                </Container>
            </Box>
            <Box component="main" sx={{ width: '100%', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/*  MOBILE PAGE COMPONENTS */}

                {/*  MOBILE PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 10, display: { xs: 'block', md: 'none' } }}>
                    <Box
                        component="img"
                        sx={{
                            height: 84,
                            width: 190,
                            ml: 'auto',
                            mr: 'auto',
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alt="The house from the offer."
                        src={bmilogoTextOnly}
                    />
                </Container >
                <Container sx={{ mt: '3rem', display: { xs: 'block', md: 'none' } }}>
                    <Typography textAlign="center" gutterBottom="true" fontWeight="600" fontSize="14px" sx={{ mt: 0, color: '#60A5FA' }}>404 Error</Typography>
                </Container >
                {/*  DESKTOP PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: '-1rem', display: { xs: 'block', md: 'none' } }}>
                    <Typography textAlign="center" gutterBottom="true" fontWeight="800" fontSize="32px" sx={{ mt: 0, color: '#262626' }}>This page does not exist.</Typography>
                </Container >
                <Container sx={{ mt: '-1rem', display: { xs: 'block', md: 'none' } }}>
                    <Typography textAlign="center" gutterBottom="true" fontWeight="400" fontSize="18px" sx={{ mt: 0, color: '#737373' }}>The page you are looking for could not be found</Typography>

                </Container >

                <Container sx={{ mt: 5.5, height: '4rem', display: { xs: 'block', md: 'none' } }}>
                    <div className="row-no-left-margin">
                        <div className="center">
                            <Link key="1" to="/"
                                style={{ textDecoration: 'none' }}
                            >
                                Or go back to home
                            </Link>
                        </div>
                    </div>
                </Container>

              
            </Box>
        </>



    )
}



export default NotFound
import { useForm, SubmitHandler, Controller, useFieldArray, FormProvider, useFormContext } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import { BatchContext, DocViewerContext } from "../../App.js";
//import { ViewerContext } from "../../pages/Viewer";
import Tooltip from '@mui/material/Tooltip';
import { useMsal } from '@azure/msal-react';
import { loginRequest, b2cPolicies } from "../../authConfig";
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';


export default function DocumentForm({familyDetails, employeeDetails, docDetails})
{

    const {
        docNum, numDocs, prevDocument, nextDocument, fetchBatch,
        documentDependentProgress, setDocumentDependentProgress,
        handleUpdateDocumentDependentProgressIndicator, documentTypes, setDocumentTypes, relationshipTypes, setRelationshipTypes
    } = useContext(DocViewerContext)


    const { control, register, handleSubmit, reset, watch, formState: { errors }, setValue } = useForm();
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "documentType",

    });
    const methods = useForm();
    const [count, setCount] = useState(0);

    //todo: record data based on document
    //submit document with document id 
    //select document type
    //const docFormTypes = [{ value: 'birthCertificate', label: 'Birth Certificate', id: 5 }, { value: 'marriageCertificate', label: 'Marriage Certificate', id: 6 }, { value: 'jointOwnership', label: 'Joint Ownership', id: 7 }, { value: 'jointTaxReturn', label: "Joint Tax Return", id: 8 }, { value: 'other', label: 'Other' }];
    //const relationshipFormTypes = [{ id: 4, value: 'legalSpouse', label: 'Legal Spouse' }, { id: 3, value: 'naturalChild', label: 'Natural Child' }, { value: 'other', label: 'Other' }];
    const [docFormTypes, setDocFormTypes] = useState([]);
    const [relationshipFormTypes, setRelationshipFormTypes] = useState([]);
    const [docQuestions, setDocQuestions] = useState(null);
    const [relationshipType, setRelationshipType] = useState();
    const [dependentList, setDependentList] = useState([]);
    const [relationship, setRelationship] = useState();
    const [selectedDependent, setSelectedDependent] = useState([]);
    const [renderDependentName, setRenderDependentName] = useState(false);
    const [sendToAiq, setSendToAiq] = useState(false);
    const { documentListState, setDocumentListState, batchCount, setBatchCount } = useContext(BatchContext);
    const { accounts, instance } = useMsal();
    const [docQuestionsList, setDocQuestionsList] = useState([]);
    const [documentTypeId, setDocumentTypeId] = useState(null);

    console.log("FamilyDetails: ", familyDetails)
    console.log("DocumentListState: ", documentListState)

    const [isDisabled, setIsDisabled] = useState(false);
    const [tempDocState, setTempDocState] = useState([]);

    useEffect(() => {
        {documentListState.map((documentState, index) => {
            if(documentState?.id === docDetails?.doc_Que_Id){
                if(documentState?.isSubmitted === true){
                    setIsDisabled(true);
                }
            }
        })
        }
    }, [setDocumentListState, documentListState]);

    useEffect(() => {
        setDocumentTypeId(null); //document type value for form textfield select when document is changed
        setSelectedDependent([]);
        
    }, [docNum]);


    useEffect(() => {
        //map relationship types to 'relationship  form types'
        setRelationshipFormTypes([]);
        relationshipTypes?.relationships?.map((relationship) => {
            setRelationshipFormTypes(relationshipFormTypes => [...relationshipFormTypes, { "id": relationship?.relationshipId, "value": relationship?.relationshipCode, "label": relationship?.relationshipDescription }]);
        })


    }, [setRelationshipTypes]);

    useEffect(() => {
        // setDocQuestions([{id: 86, description: "Is the dependent name on the document?", value: null}, {id: 90, description: "Is the employee name on the document?", value: null},  {id: 95, description: "Is the dependent birth date on the document?", value: null},  {id: 96, description: "Is there an official stamp, seal, or signature on the document?", value: null},]);

        //map document types to 'doc form types'
        setDocFormTypes([]);
        setDocQuestionsList([]);
        documentTypes?.documents?.map((documentType) => {
            setDocFormTypes(docFormTypes => [...docFormTypes, { "id": documentType?.documentId, "value": documentType?.documentId, "label": documentType?.description }]);

            documentType?.documentElements?.map((element) => {

                setDocQuestionsList(docQuestionsList => [...docQuestionsList, { "documentId": documentType?.documentId, "id": element?.documentElementId , "description": element?.languagesQuestion[0]?.description , "value": null }]);
            })

           

        })

        //add other question
        setDocQuestionsList(docQuestionsList => [...docQuestionsList, { "documentId": "Other", "id": "Other", "description": "Are you sure this document type is not listed?", "value": null}])


    }, [setDocumentTypes]);

    //set names and relationships to not be related. 
    
   
    useEffect(() => {

        //const
        console.log('docList state - ', documentListState);
        console.log("relationshipType - ", relationshipType);
        switch(relationshipType?.id){ //sorts family members (dependents, spouses) by member relationship provided in family batch (S, D)
            case relationshipFormTypes[0]?.id:
                    // console.log(relationshipType);
                    // console.log("family dependent - ", familyDetails);
                    //setDependentList([]);
                    console.log("familyDetails - ", familyDetails);
                    {familyDetails?.map((familyDependent, index) => {
                        console.log('Family Dependent - ', familyDependent);
                            if(familyDependent?.memberRelationship?.includes("S") === true){
                                console.log("id -", familyDependent?.id);
                               
                                setDependentList(dependentList => [...dependentList, familyDependent]);
                                console.log("dependentList - ", dependentList);
                            }
                        })
                    }
                break;
            case relationshipFormTypes[1]?.id:
                // console.log(relationshipType);
                // console.log("family dependent - ", familyDetails);
                //setDependentList([]);
                console.log("familyDetails - ", familyDetails);
                
                {familyDetails?.map((familyDependent, index) => {
                    console.log('Family Dependent - ', familyDependent);
                    
                        if(familyDependent?.memberRelationship?.includes("D") === true){
                            console.log("id -", familyDependent?.id);
                            console.log('Family Dependent - ', familyDependent?.DependentRelationship);
                           
                            setDependentList(dependentList => [ ...dependentList, familyDependent]);
                            console.log("dependentList - ", dependentList);
                        }
                    })
                }
                break;
            default:
                break;
        
        }


    },[renderDependentName]);



    function selectDocumentQuestions(value){

        console.log("select document questions value - ", value);

        if ((relationshipType !== null) && (familyDetails !== null)){
          
        }
        setDocQuestions([]);
        docQuestionsList?.map((docQuestion) => {

            if (docQuestion?.documentId === parseInt(value)) {
                setDocQuestions(docQuestions => [...docQuestions, docQuestion])
            }
           

        });
        //setDocQuestions([{ id: 87, description: "Is the dependent name on the document?", value: null }, { id: 91, description: "Is the employee name on the document?", value: null }, { id: 94, description: "Is the date of marriage on the document?", value: null }, { id: 97, description: "Is there an official stamp, seal, or signature on the document?", value: null },]);
        //        setDependentList([]);
        //        setRenderDependentName(!renderDependentName);
        //        setRelationshipType(relationshipFormTypes[0]);

        setDependentList([]);
        setRenderDependentName(!renderDependentName);
        //need to select relationship to determine relationship documents 
        setRelationshipType()
    }

   

    const [relationshipList, setRelationshipList] = useState([]);
    const [filteredDocumentTypeList, setFilteredDocumentTypeList] = useState([]);
    const [filteredFamily, setFilteredFamily] = useState([]);
    const [filteredDependents, setFilteredDependents] = useState([]);
    const [filteredSpouse, setFilteredSpouse] = useState([]);
    const [filteredEmployee, setFilteredEmployee] = useState([]);
    const [sortFamily, setSortFamily] = useState(null); //sorts family after each member type as been filtered in correct categories (filteredEmployee, filteredSpouse, filteredDepdendents)

    useEffect(() => {
        setFilteredFamily([]);
        setFilteredDependents([]);
        setFilteredEmployee([]);
        setFilteredSpouse([]);
       

        //filters dependents and employee details (auditId, and familyId) and sorts for "member field "
        familyDetails?.map((member) => {

            if (member?.memberId && member?.memberRelationship?.includes("D") === true) {
                setFilteredDependents(filteredDependents => [...filteredDependents, member]);
            }

            if (member?.memberId && member?.memberRelationship?.includes("S") === true) {
                setFilteredSpouse(filteredSpouse => [...filteredSpouse, member]);
            }

        });

        employeeDetails?.map((employee) => {

            if (employee?.memberId) {
                setFilteredEmployee(filteredEmployee => [...filteredEmployee, employee]);
            }
        });

        setSortFamily(true);
      
     


    }, [familyDetails, employeeDetails]);


    useEffect(() => {

        if (sortFamily === true) {
            filteredSpouse?.map((spouse) => {
                setFilteredFamily(filteredFamily => [...filteredFamily, spouse]);
            });

            filteredDependents?.map((dependent) => {
                setFilteredFamily(filteredFamily => [...filteredFamily, dependent]);
            });

            filteredEmployee?.map((employee) => {
                setFilteredFamily(filteredFamily => [...filteredFamily, employee]);
            });

            //setSortFamily(false);

        }


    }, [setSortFamily, sortFamily]);

    //useEffect(() => {
    //    setSortFamily(true);

    //}, [filteredEmployee, setFilteredEmployee]);

    useEffect(() => {

        console.log('filtered family ---', filteredFamily);

        //if (filteredFamily !== null) {
        //    setSortFamily(false);

        //}

    }, [setFilteredFamily, filteredFamily]);


    function selectRelationshipType(value) { 
        //TODO: set relationship type based on dropdown selection
        setRelationshipList([]);
        setFilteredDocumentTypeList([]);
        setRelationshipType(value); //sets relationship type provided by form dropdown
        //after relationship is selected, filter applicable documents that relation to relationship document id
        //getRelationships: realtionship { relationshipId:, description, relationshipColde, typeId, fileCode, active, languageRelationship, relationshipDocuments [{ realtionshipDocumentId, documentId, descirption, languagedocuments []}]}
        if (docFormTypes?.length > 0) {
            docFormTypes?.map((docFormType) => { //where value (relationship?.realtionships?.documentId) === documentTypeId, include in the form dropdown

                value?.relationshipDocuments?.map((relationshipDocument) => {
                    if (relationshipDocument?.documentId === docFormType?.id) {

                        console.log('document type list filtered!');
                        setFilteredDocumentTypeList(filteredDocumentTypeList => [...filteredDocumentTypeList, docFormType]);
                    }

                });
             
            });
        }

        //TODO: still need to filter supported documents based on relationship type perform at fetch??
        //useEffect that is dependent on getRelationships and getDocuments calls succeeding

       

    }

    useEffect(() => {

        if ((relationshipTypes?.length > 0) && (docFormTypes?.length > 0)) {
            //relationship -> documents related to relationship (only active relationships)
            //where value (relationship?.realtionships?.documentId) === documentTypeId, include in the form dropdown
            //add render dependentName here?????
            setFilteredDocumentTypeList([]);
            console.log("docFormTypes ===> ", docFormTypes);
            relationshipTypes?.map((relationship) => {
                relationship?.relationshipDocuments?.map((relationshipDocument) => {
                    docFormTypes?.map((docFormType) => {

                        if (relationshipDocument?.documentId === docFormType?.id) {
                            setFilteredDocumentTypeList(filteredDocumentTypeList => [...filteredDocumentTypeList, docFormType]);
                        }

                        
                    });
                   
                })

            });

            //add other option
            setFilteredDocumentTypeList(filteredDocumentTypeList => [...filteredDocumentTypeList, {id: "Other", value: "Other", label: "Other"}])
        }
      
    }, [relationshipTypes, documentTypes, docFormTypes]);


    const[selection, setSelection] = useState(false);

    function handleSelection(id, value){
        const newQuestions = docQuestions?.map((question) => {
            if(question?.id === id){
                question.value = value;
                return question;
            }else{
                return question;
            }

            

        });
      
    
        setDocQuestions(newQuestions);
    }

    useEffect(() => {
        setIsDisabled(false);
        setTempDocState([]);
    }, []);
    
    useEffect(() => {
        //reset({ documentType: "", dependentId: "" });
        setDocQuestions([]);
        setRenderDependentName(false);
        setCount(0);
        setSendToAiq(false);
        setDependentList([]);
        setRelationshipType();
        {documentListState.map((document) => {
            if(document?.id === docDetails?.doc_Que_Id && document?.isSubmitted === false){
                setIsDisabled(false);
            }
            if(document?.id === docDetails?.doc_Que_Id && document?.isSubmitted === true){
                setIsDisabled(true);
            }
        })
        
        }
    }, [docDetails])

    useEffect(() => {
        console.log("doc list state on submit", documentListState);
    }, [setDocumentListState]);

    
    function tempFunction(id){
        const newArr = documentListState.map((documentState) => {
            if(documentState?.id === id){
                return {id: documentState?.id, isSubmitted: true};
            
                
            }else{
               return documentState;
            }
        })

        setDocumentListState(newArr);
        
    }  

    useEffect(() => {
        //console.log("docQuestions count - ", count);
      
        docQuestions?.map((question) => {
            console.log("question value - ", question?.value);
            if(question?.value === 1){
              
                setCount(count + 1);
                
                //console.log("count:  ", count);
            }
        });

     

    }, [docQuestions, setDocQuestions]);

    useEffect(() => {

        console.log("docQuestions count - ", count);
        
        console.log("docQuestions length - ", docQuestions?.length);
        if(count === docQuestions?.length){
            setSendToAiq(false);
            //setCount(0);
            console.log("set send to aiq = false");
        }
        if(count !== docQuestions?.length){
            setSendToAiq(true);
            //setCount(0);
            console.log("set send to aiq = true ");
        }

    }, [count]);

    /*//TODO - Look at how we can modify the dependent status to sent, such that we can rely on it for the indicator*/

    const [tempQuestionsArr, setTempQuestionsArr] = useState([]); //temp array to reset question values after successful form submission
    const [tempDocId, setTempDocId] = useState(null);
    const [resetQuestions, setResetQuestions] = useState(false);
  
    const onSubmit = (data) => {

        let counter = 0;
        let questionsAnswered = 0;
    
        const questionResponses = docQuestions?.map((question) => {
           
            if(question?.value === 1){
                counter = counter + 1;
            }

            if (question?.value === 0 || question?.value === 1) {
                questionsAnswered = questionsAnswered + 1;
            }

            return { documentElementId: question?.id, response: question?.value}
        });

        let sendAiq = true;

        console.log("counter ---", counter);
        console.log("docQuestion?.length ---", docQuestions?.length);

        if(docQuestions?.length === counter){ //if all questions are yes, do not send document to aiq
            sendAiq = false;   
           
        }

        if (docQuestions?.length !== questionsAnswered) { //if all questions have not been answered, throw validation error on button groups
            setIsButtonValid(true);
            setIsDisabled(false);
        }

        
       

        console.log("form submission: ", JSON.stringify({ "eligibilityDocumentId": docDetails?.id, "documentTypeId": documentTypeId, "dependentId": selectedDependent?.memberId, "sendToAiq": sendAiq, "questionResponses": questionResponses }));


        //TODO: INDICATOR ERRORS need to support employee
        //if (data?.dependentId != null) {
        //    let depID = data?.dependentId;
        //    handleUpdateDocumentDependentProgressIndicator(depID, 1);
        //}
      
        

       

        console.log("initial form submission: ", data);
    
        async function submitData() {
            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Processing/UpdateDocumentResponse',
                                {
                                    method: 'POST',
                                    mode: 'cors',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + result.idToken,
                                    },
                                    body: JSON.stringify({ "eligibilityDocumentId": docDetails?.id, "documentTypeId": documentTypeId, "dependentId": selectedDependent?.memberId, "sendToAiq": sendAiq, "questionResponses": questionResponses }),
                                })
                                .then(res => res.json())
                                .then(
                                    (response) => {

                                        console.log("response - ", response);
                                    }
                                ).catch((error) => { console.log(error) });
                        }
                        fetchData();
                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }
            console.log("this is where form data is submitted for doc processing ^^^^^^");
            console.log('sendToAiq after submission --', sendAiq);
        }
      
       

        //check if selects were filled in form
        if (documentTypeId === null) {
            setIsDocTypeValid(true);
            setIsDisabled(false);
        }

        if ((selectedDependent?.memberId === null) || (selectedDependent?.memberId === undefined)) {
            setIsMemberNameValid(true);
            setIsDisabled(false);
        }

        console.log("dependentId === ", selectedDependent?.memberId);
        console.log("documentTypeId === ", documentTypeId);

        if ((documentTypeId !== null) && (selectedDependent?.memberId !== null) && (selectedDependent?.memberId !== undefined) && (docQuestions?.length === questionsAnswered) && (questionsAnswered !== null)) {//ensure a dependent was selected, a documenttype was selected, and questions were all selected before submitting form
            //submitData gets called here
            console.log("submitData called");
            submitData();
            setIsMemberNameValid(false);
            setIsDocTypeValid(false);
            setIsButtonValid(false);
            reset({ dependentId: '', documentType: '' });
            setDocQuestions([]);
            setDocQuestionsList([]);
            setDocumentTypeId(null); //document type value for form textfield select
            setSelectedDependent([]);
            setRenderDependentName(false);
            setDependentList([]);
            setSortFamily(false);
            setRelationshipType();
            tempFunction(docDetails?.doc_Que_Id);
            setBatchCount(batchCount + 1);
            //setTempQuestionsArr([]);
            console.log("docQuestionsList on submit ", docQuestionsList);

            if (!(docNum >= numDocs - 1) == true) {
                nextDocument();
            }

            //TODO: reset values for documentQuestions on successful submission
            //docQuestions?.map((question) => {
            //    //reset all values for each question
            //    setTempQuestionsArr(tempQuestionsArr => [...tempQuestionsArr, { "documentId": question?.documentId, "id": question?.documentElementId, "description": question?.description, "value": null }]); //reset question value in docQuestion Arr
            //});

            //setTempDocId(docQuestions[0]?.documentId);
            setResetQuestions(!resetQuestions);

           
            toast("Submission sent!");



        }
  
   

    };

    useEffect(() => {

        setDocFormTypes([]);
        setDocQuestionsList([]);
        documentTypes?.documents?.map((documentType) => {
            setDocFormTypes(docFormTypes => [...docFormTypes, { "id": documentType?.documentId, "value": documentType?.documentId, "label": documentType?.description }]);

            documentType?.documentElements?.map((element) => {

                setDocQuestionsList(docQuestionsList => [...docQuestionsList, { "documentId": documentType?.documentId, "id": element?.documentElementId, "description": element?.languagesQuestion[0]?.description, "value": null }]);
            })



        })

        //add other question
        setDocQuestionsList(docQuestionsList => [...docQuestionsList, { "documentId": "Other", "id": "Other", "description": "Are you sure this document type is not listed?", "value": null }])

    }, [resetQuestions, setResetQuestions]);


    //const [tempDocQuestionsList, setTempDocQuestionsList] = useState([]);
    //function resetDocumentTypeQuestions() {

    //    //if ((docQuestions !== null) && (tempQuestionsArr !== null)) {
    //    //    if ((docQuestions?.length !== 0) && (tempQuestionsArr?.length !== 0) && (docQuestions?.length === tempQuestionsArr?.length)) {
    //    //        setDocQuestions(tempQuestionsArr); //reset doc questions array
    //    //        console.log("set docs called", tempQuestionsArr);
    //    //    }
    //    //}
    //    console.log("tempDocQuestionsList after reset = ", tempDocQuestionsList);
    //    setDocQuestionsList(tempDocQuestionsList);
    //    setTempDocId(null);

    //    setResetQuestions(false);
    //}

    //useEffect(() => {

    //    if (resetQuestions === true) {
    //        resetDocumentTypeQuestions(); 
    //    }

    //}, [setResetQuestions, resetQuestions]);

    //useEffect(() => {

    //    if (tempDocId !== null) {
    //        docQuestionsList?.map((question) => {
    //            setTempDocQuestionsList(tempDocQuestionsList => [...tempDocQuestionsList, { documentId: question?.documentId, id: question?.id, description: question?.description, value: null }]);
    //        });
    //    }

    //}, [setTempDocId, tempDocId]);

    const [selectedName, setSelectedName] = useState("");
    const [selectedDocumentType, setSelectedDocumentType] = useState("");

    const handleMemberChange = (e) =>{
        //handles change of member name in form
        console.log("e handleMemberChange - ", e);
        setSelectedDependent(e);

    }


    const handleDocumentTypeChange = (e) => {
        //handles change of document type and sets questions in form
        console.log('e documentTypeId ', e);
        //selectDocumentQuestions(e);
        setDocumentTypeId(e);
        selectDocumentQuestions(e);
    }

    //useEffect(() => {
    //    selectDocumentQuestions(documentTypeId);

    //}, [setDocumentTypeId]);

    const [isMemberNameValid, setIsMemberNameValid] = useState(false);
    const [isDocTypeValid, setIsDocTypeValid] = useState(false);
    const [isButtonValid, setIsButtonValid] = useState(false);

    return (<>
        {/* <FormProvider {...methods}> */}
        <form>
            <Box component="form" sx={{ '& .MuiTextField-root': { mt: '1.5VH', mb: 2, width: '25ch', ml: '2VW' } }}>
                {/*  Relationship Type tied to document type id */}
                {/*<Controller
                    name="relationshipType"
                    defaultValue=""
                    control={control}
                    render={({ field }) => <TextField select label="Relationship Type" variant="outlined" {...field}>{relationshipTypes?.map((option) => (<MenuItem onClick={() => selectRelationshipType(option)} key={option?.relationshipId} value={option?.relationshipId}>{option?.description}</MenuItem>))}</TextField>}
                // rules={{ required: true }}
                /> */}
                {/*<Controller*/}
                {/*    name="documentType"*/}
                {/*    defaultValue=''*/}
                {/*    control={control}*/}
                {/*    render={({ field }) => <TextField select label="Document Type" variant="outlined" {...field}>{docFormTypes?.map((option) => (<MenuItem onClick={() => selectDocumentQuestions(option.value)} key={option.value} value={option.id}>{option.label}</MenuItem>))}</TextField>}*/}
                {/*    // rules={{ required: true }}*/}
                {/*/>*/}

                <Controller
                    name="dependentId"
                    control={control}
                    //TODO: Add active state and onchange -- double check if form data persists correctly
                    render={({ field }) => <TextField select label="Member Name" variant="outlined" disabled={isDisabled === true} value={selectedDependent} error={isMemberNameValid} SelectProps={{ onChange: (e) => {  handleMemberChange(e.target.value); }}} >{filteredFamily?.map((dependent, index) => (<MenuItem key={index} value={dependent}>{dependent?.firstName + " " + dependent?.lastName}</MenuItem>))}</TextField>}
                    
                    //render={({ field }) => <TextField select label="Member Name" variant="outlined" {...field}>{filteredFamily?.map((dependent, index) => (<MenuItem onClick={() => setSelectedDependent(dependent)} key={index} value={dependent}>{dependent?.firstName + " " + dependent?.lastName}</MenuItem>))}</TextField>}
                // rules={{ required: true }}
                />

                {filteredDocumentTypeList ? (<>
                    <Controller
                        name="documentType"
                        //defaultValue={filteredDocumentTypeList ? filteredDocumentTypeList[0]?.id : ''}
                        control={control}
                        render={({ field }) => <TextField select label="Document Type" variant="outlined" disabled={isDisabled === true} value={documentTypeId} error={isDocTypeValid} SelectProps={{ onChange: (e) => { handleDocumentTypeChange(e.target.value); }}}>{filteredDocumentTypeList?.map((option, index) => (<MenuItem key={index} value={option.value}>{option.label}</MenuItem>))}</TextField>}
                        //render={({ field }) => <TextField select label="Document Type" variant="outlined" {...field}>{filteredDocumentTypeList?.map((option) => (<MenuItem onClick={() => selectDocumentQuestions(option.value)} key={option.value} value={option.id}>{option.label}</MenuItem>))}</TextField>}
                    // rules={{ required: true }}
                    />
                </>) : (<></>)}

              
               
                {/* <QuestionInputFields/> */}

                {/* old name select tied to document type select  */}
                {/* {relationshipType ? (

                    <Controller
                    name="dependentId"
                    control={control}
                    render={({ field }) => <TextField select label="Dependent Name" variant="outlined" {...field}>{dependentList?.map((dependent, index) => (<MenuItem onClick={() => setSelectedDependent(dependent)} key={index} value={dependent?.memberId}>{dependent?.firstName + " " + dependent?.lastName}</MenuItem>))}</TextField>}
                    // rules={{ required: true }}
                    />
                ) : (<></>)} */}

                {docQuestions ? (
                <Box>
                    {docQuestions?.map((question, index) => {
                        return(
                            <Box flex-wrap="wrap" sx={{ mt: 1, mb: 1, ml: '2VW' }}>
                       
                        
                       
                        {/* <h3>{question?.description}</h3> */}
                            <Typography key={index} variant="h3" sx={{ fontSize: "1rem", fontWeight: "bold" }} gutterbottom>{question?.description}</Typography>
                            {/* <ToggleButtonGroup color="primary" exclusive value={docQuestions} onChange={handleSelection(question?.id)} key={index} label={question?.id} >
                                    <ToggleButton  value="1">Yes</ToggleButton>
                                    <ToggleButton value="0">No</ToggleButton>
                            </ToggleButtonGroup>  */}
                            <ButtonGroup exclusive key={index} color={isButtonValid ? 'error' : 'primary' }>
                                    <Button onClick={() => handleSelection(question?.id, 1)} variant={question?.value === 1 ? "contained" : "outlined"}>Yes</Button>
                                    <Button onClick={() => handleSelection(question?.id, 0)} variant={question?.value === 0 ? "contained" : "outlined"}>No</Button>
                            </ButtonGroup>   
                        
                            {/* <Controller
                            
                                key={question?.id}
                                name={`question_${question?.id}`}
                                control={control}
                                render={({ field }) => {
                                <>
                                
                                </>
                            
                                }}
                            /> */}
                        
                        </Box>
                        );
                    })}
                </Box>
                ) : (<></>)}
      
              
            
            {/* <Controller
                name="employeeFullName"
                control={control}
                render={({ field }) => <TextField label="Employee Name" variant="outlined" {...field}/>}

            />
            <Controller
                name="dependentFullName"
                control={control}
                render={({ field }) => <TextField label="Dependent Name" variant="outlined" {...field}/>}

            />
         
            <Controller
                name="relationshipType"
                control={control}
                render={({ field }) => <TextField select label="Relationship Type" variant="outlined" {...field}>{relationshipTypes.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}</TextField>}

            /> */}
             {/* <Controller
                name="notes"
                control={control}
                render={({ field }) => <TextField multiline label="Notes" variant="outlined" {...field}/>}

            /> */}
            {/* <Controller
                name="submit"
                type="submit"
                control={control}
                render={}

            /> */}
                <Tooltip title="Submit Document Form">
                  {/*  <Button sx={{ pt: 1, mt: 1, ml: '4VW' }} label="Submit" variant="contained" onClick={handleSubmit(onSubmit)} disabled={isDisabled === true}>SUBMIT</Button>*/}
                    <Button sx={{ pt: 1, mt: 0.5, ml: '2VW', backgroundColor: '#0059a8' }} label="Submit" variant="contained" onClick={handleSubmit(onSubmit)} disabled={isDisabled === true}>SUBMIT</Button>
                </Tooltip>
            {/* <input placeholder="Employee Name" {...register("employeeFullName")} /> */}
            {/* <input placeholder="Dependent Name" {...register("dependentFullName")} /> */}

            {/* <select {...register("documentType", { required: true})}>
                <option value="">Document Type</option>
                <option value="birthCertificate">Birth Certificate</option>
                <option value="marriageCertificate">Marriage Certificate</option>
                <option value="other">Other</option>
            </select> */}
            {/* <select {...register("relationshipType", {required: true})}>
                <option value="">Relationship Type</option>
                <option value="Legal Spouse">Legal Spouse</option>
                <option value="Natural Child">Natural Child</option>
                <option value="other">Other</option>
            </select> */}
            {/* <textarea {...register("notes")} placeholder="Notes" /> */}
            {/* <input type="submit" /> */}
 
            </Box>
        </form>
        {/* </FormProvider> */}
        {/* <ToastContainer
            position="bottom-center"
            autoClose={1000}
            theme="light"
            closeOnClick
            hideProgressBar={false}
            pauseOnFocusLoss
            newestOnTop={false}
            transition={Bounce}

        />*/}
    
    </>);
}
import { useState, useEffect, useRef, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { IoCheckmarkCircle, IoCheckmarkCircleOutline } from "react-icons/io5";
//import { ViewerContext } from "../../pages/Viewer";
import { DocViewerContext } from "../../App.js";
import { format, formatISO } from 'date-fns'
export default function FamilyDetails({ familyDetails, employeeDetails }) {

    const {
        docNum, numDocs, prevDocument, nextDocument, fetchBatch,
        documentDependentProgress, setDocumentDependentProgress,
        handleProvideDocumentProcessStatusByDependentID
    } = useContext(DocViewerContext)

    const [sortedFamily, setSortedFamily] = useState([]); //used to hold sorted family for dependent details (spouse, dependents) when applicable
    const [spouseDetails, setSpouseDetails] = useState([]); //holds spouse details from family details for sorting
    const [dependentDetails, setDependentDetails] = useState([]); //holds dependent details from family details for sorting
    const [sortFamily, setSortFamily] = useState(false); //flags families to be sorted on familyDetails and spouseDetails load.

    useEffect(() => {
        setSortedFamily([]);
        setSpouseDetails([]);
        setDependentDetails([]);
        familyDetails?.map((member) => {

            if (member?.memberId && member?.memberRelationship?.includes("S") === true) {
                setSpouseDetails(spouseDetails => [...spouseDetails, member]);
            }

            if (member?.memberId && member?.memberRelationship?.includes("D") === true) {
                setDependentDetails(dependentDetails => [...dependentDetails, member]);
            }

            
        });
        console.log("Family Details - setSortFamily called");
        setSortFamily(true);

    }, [familyDetails, employeeDetails]);



    useEffect(() => {
        //setSortedFamily(sortedFamily => [...sortedFamily, member]);
        if (sortFamily === true) {
            spouseDetails?.map((spouse) => {
                setSortedFamily(sortedFamily => [...sortedFamily, spouse]);

            });

            dependentDetails?.map((dependent) => {
                setSortedFamily(sortedFamily => [...sortedFamily, dependent]);
            });
            setSortFamily(false);
        }
    }, [setSortFamily, sortFamily]);
    
    console.log("FamilyDetails - Within Component: ", familyDetails)
    console.log("EmployeeDetails - Within Component: ", employeeDetails)
    console.log(" documentDependentProgress - Within Component: ", documentDependentProgress)

    function renderDate(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'MM/dd/yyyy');
    }

    return (
        <>
            {/* XL DESKTOP VARIANT */}
        <Box sx={{ mt: '-1.5VH', ml: '4.5VW', width: '18.75VW', bgcolor: '#ffffff', height: '86VH', borderLeft: '2px solid #e5e5e5', display: { xs: 'none', md: 'none', xl: 'block' } }}>
        <div>
        {employeeDetails ? (
                    <Box sx={{ width: '18.75VW' }}>
                        <Box sx={{ bgcolor: '#F6F7F8', height: '26px', width: '100%', mb: '0.5VW'   }}>
                            <Typography textAlign="left" fontWeight="700" fontSize="20px" component="div" sx={{ color: '#262626', ml: '0.75VW' }}>

                   Employee Details

                            </Typography>
                        </Box>
                        {/* <Typography variant="h4" gutterbottom>Employee Details</Typography>*/}
                        <Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%',   }}>
                            <Typography fontWeight="600" fontSize="13px" sx={{ color: '#262626', ml: '0.75VW' }}> {employeeDetails[0]?.firstName + " " + employeeDetails[0]?.lastName}</Typography>
                        </Box>
                        {/*<Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%', }}>*/}
                        {/*    <Typography fontWeight="600" fontSize="13px" sx={{ color: '#000000', ml: '0.75VW' }}> {employeeDetails[0]?.lastName}</Typography>*/}
                            {/*</Box>*/}
                        <Box sx={{ height: '26px', width: '100%' }}>
                            <Typography fontWeight="600" fontSize="12px" sx={{ color: '#262626', ml: '0.75VW' }}> {'DOB: ' + renderDate(employeeDetails[0]?.birthDate)}</Typography>
                        </Box>
                       
                        <Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%',  }}>
                            <Typography fontWeight="600" fontSize="13px" sx={{ color: '#666666', ml: '0.75VW' }}> {employeeDetails[0]?.addressPre + " " + employeeDetails[0]?.address1}</Typography>
                        </Box>
                        <Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%', }}>
                            <Typography fontWeight="600" fontSize="13px" sx={{ color: '#666666', ml: '0.75VW' }}>   {employeeDetails[0]?.address2 + employeeDetails[0]?.city + ", " + employeeDetails[0]?.state + " " + employeeDetails[0]?.zip}</Typography>
                        </Box>
                      {/*  <Divider variant="middle" component="div" />*/}
                            {/*  <Typography variant="body1" gutterbottom>Name: {employeeDetails[0]?.firstName + " " + employeeDetails[0]?.lastName}</Typography>*/}
            {/*   <Typography variant="body1" gutterbottom>Address: {employeeDetails[0]?.addressPre + " " + employeeDetails[0]?.address1 + ". " + employeeDetails[0]?.address2 + ", " + employeeDetails[0]?.city + ", " + employeeDetails[0]?.state + " " + employeeDetails[0]?.zip}</Typography>*/}

                        </Box>) : (<></>)}
        {sortedFamily ? (
                    <Box sx={{ width: '18.75VW' }}>
                        <Box sx={{ bgcolor: '#F6F7F8', height: '26px', width: '100%', mb: '0.5VW'  }}>
                            <Typography textAlign="left" fontWeight="700" fontSize="20px" component="div" sx={{ color: '#262626', ml: '0.75VW' }}>

                                 Dependent Details

                            </Typography>
                        </Box>
           {/* <Typography variant="h4" gutterbottom>Dependent Details</Typography>*/}
            {/* {console.log(familyDetails)} */}
            {sortedFamily?.map((dependent) => {
                if(dependent?.auditId){
                    return(<></>);
                }
                else if(dependent?.familyId){
                    return(<></>);
                }else if(dependent?.memberId){
                    return(
                        <Box sx={{ width: '18.75VW' }}>
                            <Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%',  }}>
                                <Typography fontWeight="600" fontSize="13px" sx={{ color: '#262626', ml: '0.75VW' }}> {dependent?.firstName + " " + dependent?.lastName}</Typography>
                            </Box>
                            {/*<Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%', }}>*/}
                            {/*    <Typography fontWeight="600" fontSize="13px" sx={{ color: '#666666', ml: '0.75VW' }}> {dependent?.lastName}</Typography>*/}
                            {/*</Box>*/}
                            <Box sx={{ height: '26px', width: '100%' }}>
                                <Typography fontWeight="600" fontSize="13px" sx={{ color: '#262626', ml: '0.75VW' }}> {'DOB: ' + renderDate(dependent?.birthDate)}</Typography>
                            </Box>
                            <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 0.1fr)'}}>
                                <Box sx={{ width: "5%", ml: '0.5VW', height: '26px'}}>
                                {handleProvideDocumentProcessStatusByDependentID(dependent?.memberId) == 1 ? (<>
                                    <IoCheckmarkCircle className="dependentIndicator" />
                                </>) : (<>
                                    <IoCheckmarkCircleOutline className="dependentIndicator--unfilled" />
                                </>)}
                                </Box>
                           
                                <Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '95%', ml: '0.05VW'  }}>
                                    {/*  <Typography fontWeight="600" fontSize="14px" sx={{ color: '#262626' }}>Relationship: {dependent?.memberRelationship}</Typography>*/}
                                    {/*TODO add another conditional for D as Dependent (Ternary), everything else is defaultCase (set a debug value)*/}
                                    <Box sx={{width: '5VW'}}>
                                        <Typography fontWeight="600" fontSize="13px" sx={{ color: '#666666', ml: '0.75VW' }}>{dependent?.memberRelationship ==
                                            "S " ? 'Spouse' : 'Dependent'}</Typography>
                                    </Box>
                              
                                    {/*<Box sx={{ width: '3VW', ml: 0, mr: 0, mt: 0, mb: 0 }}>*/}
                                    {/*    <IoCheckmarkCircle className="dependentIndicator" />*/}
                                    {/*</Box>*/}
                                </Box>
                           </Box>
                            <Divider variant="middle" component="div" />
                            {/*<Typography variant="body1" gutterbottom>Name: {dependent?.firstName + " " + dependent?.lastName}</Typography>*/}
                            {/*<Typography variant="body1" gutterbottom>Relationship: {dependent?.memberRelationship}</Typography>*/}
                  
                           
                                {/*<Typography variant="body1" gutterbottom>Address: {dependent?.addressPre + " " + dependent?.address1 + ". " + dependent?.address2 + ", " + dependent?.city + ", " + dependent?.state + " " + dependent?.zip}</Typography>*/}

                          
                         
                      </Box>
                    );
                }
            }
              
            )}
        </Box>) : (<></>)}
            </div>
        </Box>

            {/* SM and MD DESKTOP VARIANT */}
            <Box sx={{ mt: '-1.5VH', ml: '2.75VW', width: '22.75VW', bgcolor: '#ffffff', height: '86VH', borderLeft: '2px solid #e5e5e5', display: { xs: 'block', md: 'block', xl: 'none' } }}>
                <div>
                    {employeeDetails ? (
                        <Box sx={{ width: '22.75VW' }}>
                            <Box sx={{ bgcolor: '#F6F7F8', height: '25%', width: '100%', mb: '0.5VW' }}>
                                <Typography textAlign="left" fontWeight="700" fontSize="20px" component="div" sx={{ color: '#262626', ml: '0.75VW' }}>

                                    Employee Details

                                </Typography>
                            </Box>
                            {/* <Typography variant="h4" gutterbottom>Employee Details</Typography>*/}
                            <Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%', }}>
                                <Typography fontWeight="600" fontSize="12px" sx={{ color: '#262626', ml: '0.75VW' }}> {employeeDetails[0]?.firstName + " " + employeeDetails[0]?.lastName}</Typography>
                                <Typography fontWeight="600" fontSize="12px" sx={{ color: '#262626', ml: '0.75VW' }}> {'DOB: ' + renderDate(employeeDetails[0]?.birthDate)}</Typography>
                            </Box>
                            <Box sx={{ height: '26px', width: '100%' }}>
                             
                            </Box>
                            {/*<Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%', }}>*/}
                            {/*    <Typography fontWeight="600" fontSize="13px" sx={{ color: '#000000', ml: '0.75VW' }}> {employeeDetails[0]?.lastName}</Typography>*/}
                            {/*</Box>*/}
                            <Box sx={{ height: '1.25VH'}}></Box>

                            <Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%', }}>
                                <Typography fontWeight="600" fontSize="12px" sx={{ color: '#666666', ml: '0.75VW' }}> {employeeDetails[0]?.addressPre + " " + employeeDetails[0]?.address1}</Typography>
                            </Box>
                            <Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%', }}>
                                <Typography fontWeight="600" fontSize="12px" sx={{ color: '#666666', ml: '0.75VW' }}>   {employeeDetails[0]?.address2 + employeeDetails[0]?.city + ", " + employeeDetails[0]?.state + " " + employeeDetails[0]?.zip}</Typography>
                            </Box>
                            {/*  <Divider variant="middle" component="div" />*/}
                            {/*  <Typography variant="body1" gutterbottom>Name: {employeeDetails[0]?.firstName + " " + employeeDetails[0]?.lastName}</Typography>*/}
                            {/*   <Typography variant="body1" gutterbottom>Address: {employeeDetails[0]?.addressPre + " " + employeeDetails[0]?.address1 + ". " + employeeDetails[0]?.address2 + ", " + employeeDetails[0]?.city + ", " + employeeDetails[0]?.state + " " + employeeDetails[0]?.zip}</Typography>*/}

                        </Box>) : (<></>)}
                    {sortedFamily ? (
                        <Box sx={{ width: '22.75VW' }}>
                            <Box sx={{ bgcolor: '#F6F7F8', height: '25%', width: '100%', mb: '0.5VW' }}>
                                <Typography textAlign="left" fontWeight="700" fontSize="20px" component="div" sx={{ color: '#262626', ml: '0.75VW' }}>

                                    Dependent Details

                                </Typography>
                            </Box>
                            {/* <Typography variant="h4" gutterbottom>Dependent Details</Typography>*/}
                            {/* {console.log(familyDetails)} */}
                            {sortedFamily?.map((dependent) => {
                                if (dependent?.auditId) {
                                    return (<></>);
                                }
                                else if (dependent?.familyId) {
                                    return (<></>);
                                } else if (dependent?.memberId){
                                    return (
                                        <Box sx={{ width: '22.75VW' }}>
                                            <Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '100%'}}>
                                                <Typography fontWeight="600" fontSize="12px" sx={{ color: '#262626', ml: '0.75VW' }}> {dependent?.firstName + " " + dependent?.lastName}</Typography>
                                                <Typography fontWeight="600" fontSize="12px" sx={{ color: '#262626', ml: '0.75VW' }}> {'DOB: ' + renderDate(dependent?.birthDate)}</Typography>
                                            </Box>
                                            <Box sx={{ height: '26px', width: '100%' }}>
                                              
                                            </Box>
                                            <Box sx={{ height: '1.25VH' }}></Box>
                                            <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 0.1fr)' }}>
                                                <Box sx={{ width: "5%", ml: '0.5VW', height: '26px' }}>
                                                    {handleProvideDocumentProcessStatusByDependentID(dependent?.memberId) == 1 ? (<>
                                                        <IoCheckmarkCircle className="dependentIndicator" />
                                                    </>) : (<>
                                                        <IoCheckmarkCircleOutline className="dependentIndicator--unfilled" />
                                                    </>)}
                                                </Box>
                                                <Box sx={{ bgcolor: '#FFFFFF', height: '26px', width: '95%',ml: '1VW'  }}>
                                               
                                                    {/*TODO add another conditional for D as Dependent (Ternary), everything else is defaultCase (set a debug value)*/}
                                                    <Typography fontWeight="600" fontSize="12px" sx={{ color: '#666666', ml: '0.75VW' }}>{dependent?.memberRelationship == "S " ? 'Spouse' : 'Dependent'}</Typography>
                                                </Box>
                                            </Box>
                                            <Divider variant="middle" component="div" />
                                         



                                        </Box>
                                    );
                                }
                            }

                            )}
                        </Box>) : (<></>)}
                </div>
            </Box>
        </>
            );

}

import React, { useState, useEffect, useContext, createContext, useRef } from 'react'
import { useTable } from 'react-table'
import { useParams, useNavigate, Navigate, Link } from "react-router-dom";
import { useForm } from 'react-hook-form'
/*import { Artboard, Card, Collapse, Stats, Table, Progress, RadialProgress, Button, Input, Select, Modal, Dropdown } from 'react-daisyui';*/
import { BsCheckLg, BsXLg, BsExclamationCircleFill, BsThreeDots } from 'react-icons/bs';
import { SidebarContext } from "../App";
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import UserManagementTable from "../components/tables/UserManagementTable";
import UserManagementTableMobile from "../components/tables/UserManagementTableMobile";
import USER_MGMT_MOCK_DATA from "../data/USER_MGMT_MOCK_DATA.json"
import UserManagementModalCreateUser from "../components/modals/UserManagementModalCreateUser";
import UserManagementModalUpdateUser from "../components/modals/UserManagementModalUpdateUser";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext, PermissionsContext, RolesContext } from "../App";
import useFetch from "../hooks/useFetch";
import Tooltip from "@mui/material/Tooltip";
import { loginRequest, b2cPolicies } from '../authConfig';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Button from '@mui/material/Button';
import { IoMdArrowRoundBack } from "react-icons/io";

import FamilyDetailsEmployeeCard from '../components/cards/FamilyDetailsEmployeeCard';
import Divider from '@mui/material/Divider';


import FamilyDetailsMembersTable from '../components/tables/FamilyDetailsMembersTable';


import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import FamilyDetailsFilesUploadedTable from '../components/tables/FamilyDetailsFilesUploadedTable';



function FamilyDetailsGlobal({ onChange, children }) {

    const {

        setSidebarTitle,
        sidebarActiveClientID,
        setSidebarActiveClientID,
        setSidebarActions
    } = useContext(SidebarContext);

    const { id, auditName, auditFamilyId, employeeId } = useParams();
    const { instance, accounts, inProgress } = useMsal();

    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);

    //client
    const [email, setEmail] = useState('');
    //const [password, setPassword] = useState('');
    const [givenName, setGivenName] = useState('');
    const [surname, setSurname] = useState('');
    const [accountEnabled, setAccountEnabled] = useState(false);
    const [currentEmail, setCurrentEmail] = useState('')
    const [displayName, setDisplayName] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [users, setUsers] = useState([]);
    //const users = useRef({});
    const [haveUserData, setHaveUserData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [loading, setLoading] = useState(false);
    //const [status, setStatus] = useState(false);
    const [updateUser, setUpdateUser] = useState(false);
    const { newRoutes, setRoutes } = useContext(RoutesContext);
    //const [fetchNewData, setFetchNewData] = useState(false);
    const navigate = useNavigate();



    const [updateUserModalOpen, setUpdateUserModalOpen] = useState(false);

    useEffect(() => {

        setSidebarTitle(`${auditName}`)
        setSidebarActiveClientID(`${id}`)
    }, [auditName, id])



    //toggle visible modal for adding user when on 'add user' button click
    const toggleVisible = () => {
        setVisible(!visible)
    }

    const toggleVisible1 = () => {
        setVisible1(!visible1)
    }

    const toggleVisible2 = () => {
        setVisible2(!visible2)
    }

    const toggleLoading = () => {
        setLoading(!loading)
    }


    const [reports, setReports] = useState([]);


    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: '',
            isNested: false
        },
        {
            id: 1,
            label: "Search All",
            path: "globalsearch",
            icon: <TravelExploreIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: false
        }
    ]);

    useEffect(() => {

        if ((roles === "CLIENT")) {
            /* <Navigate to={`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`} replace />*/

            if (permissions?.length === 1) {

                navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
            }


        }

        if ({ pageRoutes } !== { newRoutes } && (roles === "SERVICES")) {
            setRoutes([
                {
                    id: 0,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },

                {
                    id: 1,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])

        }

        if ({ pageRoutes } !== { newRoutes } && (roles === "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                {
                    id: 2,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])

        }

    }, []);

    //const [testUsers, setTestUsers] = useState([
    //    {
    //        "objectId": "05e27995-bb8f-4203-940b-7903804eb475",
    //        "email": "admin20@test.com",
    //        "givenName": "Admin",
    //        "surname": "20",
    //        "displayName": "Admin 20",
    //        "phoneNumber": "+1 1234567890",
    //        "accountEnabled": true,
    //        "finishedRegistration": true
    //    },
    //    {
    //        "objectId": "0b8b9746-5411-41cc-91be-43616c8fc41c",
    //        "email": "admin16@test.com",
    //        "givenName": "Admin",
    //        "surname": "16",
    //        "displayName": "Admin 16",
    //        "phoneNumber": "+1 1234567890",
    //        "accountEnabled": true,
    //        "finishedRegistration": true
    //    },
    //    {
    //        "objectId": "25915e34-0475-43a8-ad81-9c080314d714",
    //        "email": "admin21@test.com",
    //        "givenName": "Admin",
    //        "surname": "21",
    //        "displayName": "Admin 21",
    //        "phoneNumber": "+1 1234567890",
    //        "accountEnabled": true,
    //        "finishedRegistration": true
    //    },

    //])

    //useEffect(() => {
    //    if (accounts?.length > 0) { //if account exists authorize user
    //        const fetchUserData = async () => {
    //            await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
    //                .then(result => {
    //                    const fetchData = async () => {
    //                        await fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/GetUsers', {
    //                            method: 'POST',
    //                            mode: 'cors',
    //                            headers: {
    //                                'Content-Type': 'application/json',
    //                                'Authorization': 'Bearer ' + result.idToken,
    //                            },
    //                            body: JSON.stringify({ "auditId": id })
    //                        })
    //                            .then(res => res.json())
    //                            .then((response) => {
    //                                console.log(response)
    //                                setHaveUserData(true);
    //                                setUsers(response?.b2CUsers);

    //                                //users.current = [...response];
    //                            }).catch((error) => {
    //                                console.log(error)
    //                            })


    //                    }

    //                    fetchData();

    //                }).catch((error) => {
    //                    console.log(error) //token expired/is not valid => user is redirected to login page
    //                    if (error instanceof InteractionRequiredAuthError) {
    //                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
    //                    }

    //                });

    //        }
    //        fetchUserData();
    //    }

    //}, [haveUserData, setHaveUserData])

    let apiData = useRef(null);
    const [haveAPISearchData, setHaveAPISearchData] = useState(false);

    const [employeeDataFromSearch, setEmployeeDataFromSearch] = useState([]);

    function handleUpdateEmployeeDataFromSearch(dataToAdd) {
        setEmployeeDataFromSearch(dataToAdd)
    }

    useEffect(() => {
        fetchClientScopedDataGridRowData();
    }, [id, auditFamilyId])


    const fetchClientScopedDataGridRowData = async () => {

        const potentialRequestBody = {
            "auditNumber": auditFamilyId,
            "auditId": parseInt(id),
            "lastName": "",
            "lastNameExactMatch": false,
            "firstName": "",
            "firstNameExactMatch": false,
            "birthdate": null,
            "emailAddress": ""
        }

        if (accounts.length > 0) {
            await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                .then(result => {



                    const fetchData = async () => {
                        await fetch(process.env.REACT_APP_BACK_URL + '/api/Search/SearchAll', {
                            method: 'POST',
                            mode: 'cors',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + result.idToken,
                            },
                            body: JSON.stringify(
                                potentialRequestBody
                            )
                        })
                            .then(res => res.json())
                            .then(data => {
                                console.log("RETURNED DATA from  fetch: ", data)
                                console.log("RETURNED DATA from fetch LENGTH: ", data?.length)
                                if (data.length === 0 || data == [] || data == undefined || data == null) {
                                    console.log("Hitting response weirdness")



                                    setHaveAPISearchData(false);
                                } else {
                                    handleUpdateEmployeeDataFromSearch(data)
                                    return data;
                                }

                            })
                            .then((response) => {
                                if (response.status === 400) {
                                    console.log("hitting 400 error")
                                    /* setHaveAPISearchData(false);*/
                                    fetchData();
                                    throw Error(response.status);

                                } else {
                                    console.log("Employee Details FETCH RES: ", response)
                                    setHaveAPISearchData(true)
                                    /* setHaveAPISearchData(true);*/
                                    console.log("API DATA: ", response);
                                    apiData.current = [...response];
                                }
                                console.log("hitting neither case")


                            }).catch((error) => {
                                console.log(error)


                            })
                    }

                    fetchData();

                }).catch((error) => {
                    console.log(error) //token expired/is not valid => user is redirected to login page
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }
                });

        }





    }







    const handleNavigateBackToSearch = (params) => {

        /* console.log(params);*/
       


        navigate(`/globalsearch`);

    };

    let apiFamilyData = useRef(null);
    let apiFamilyMemberData = useRef(null);
    let apiFamilyActivityData = useRef(null);
    let apiFamilyFileData = useRef(null);


    const [familyData, setFamilyData] = useState([]);
    const [haveAPIFamilyData, setHaveAPIFamilyData] = useState(false);

    function handleUpdateFamilyData(dataToUpdate) {
        console.log("handleUpdateFamilyData: ", dataToUpdate);
        setFamilyData(dataToUpdate);
    }

    const [familyMemberData, setFamilyMemberData] = useState([]);
    const [haveAPIFamilyMemberData, setHaveAPIFamilyMemberData] = useState(false);

    function handleUpdateFamilyMemberData(dataToUpdate) {
        console.log("handleUpdateFamilyDepData: ", dataToUpdate);
        let varX = dataToUpdate;
        console.log("varX pre: ", varX)
        if (varX !== undefined) {
            let varY = varX[0]?.dependent_data;
            /* varX = [...varX,dataToUpdate?.dependent_data];*/
            console.log("varY post:", varY)
            setFamilyMemberData(varY);
        }


    }

   

    const [familyActivityData, setFamilyActivityData] = useState([]);
    const [haveAPIFamilyActivityData, setHaveAPIFamilyActivityData] = useState(false);

    function handleUpdateFamilyActivityData(dataToUpdate) {
        console.log("handleUpdateFamilyActivityData: ", dataToUpdate);
        setFamilyActivityData(dataToUpdate);
    }

    /////FILES UPLOADED



   

    const [familyFileData, setFamilyFileData] = useState([]);
    const [haveAPIFamilyFilesUploadedData, setHaveAPIFamilyFilesUploadedData] = useState(false)

    function handleUpdateFamilyFileData(dataToUpdate) {
        console.log("handleUpdateFamilyFileData: ", dataToUpdate);
        setFamilyFileData(dataToUpdate);
    }

    useEffect(() => {

        /*console.log("CLICK-ROW", params.row);*/

        let potentialAuditFamilyID = auditFamilyId;
        let potentialEmployeeID = employeeId;
        /* handleUpdateFamilyTestData(params.row.employeeID)*/
        /* TEST of GET FAMILY MEMBERS, also need one for GetFamily*/
        const fetchFamilyScopedDependentData = async () => {

            const familyScopedRequestBody = {
                "auditFamilyId": potentialAuditFamilyID,
                "employeeId": potentialEmployeeID,
            }

            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {



                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Search/GetFamilyMembers', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify(
                                    familyScopedRequestBody
                                )
                            })
                                .then(res => res.json())
                                .then(data => {
                                    console.log("RETURNED DATA from get family members: ", data)
                                    console.log("RETURNED DATA from get family members length: ", data?.length)
                                    if (data.length === 0 || data == [] || data == undefined || data == null) {
                                        console.log("Hitting response weirdness")



                                    } else {
                                        return data;
                                    }

                                })
                                .then((response) => {
                                    if (response.status === 400) {
                                        console.log("hitting 400 error")

                                        fetchData();
                                        throw Error(response.status);

                                    } else {
                                        console.log("FAMILY DEP FETCH RES: ", response)
                                        setHaveAPIFamilyMemberData(true)
                                        apiFamilyMemberData.current = [response];
                                        handleUpdateFamilyMemberData(apiFamilyMemberData.current)
                                    }
                                    console.log("hitting neither case -- family")


                                }).catch((error) => {
                                    console.log(error)


                                })
                        }

                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });

            }





        }
        const fetchFamilyScopedData = async () => {

            const familyScopedRequestBody = {
                "auditFamilyId": potentialAuditFamilyID,
                "employeeId": potentialEmployeeID,
            }

            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {



                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Search/GetFamily', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify(
                                    familyScopedRequestBody
                                )
                            })
                                .then(res => res.json())
                                .then((response) => {
                                    if (response.status === 400) {
                                        console.log("hitting 400 error")

                                        fetchData();
                                        throw Error(response.status);

                                    } else {
                                        console.log("FAMILY FETCH RES: ", response)
                                        setHaveAPIFamilyData(true)
                                        apiFamilyData.current = [response];
                                        handleUpdateFamilyData(apiFamilyData.current)
                                    }
                                    console.log("hitting neither case -- family")


                                }).catch((error) => {
                                    console.log(error)


                                })
                        }

                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });

            }





        }
        const fetchFamilyScopedActivityData = async () => {

            const familyScopedRequestBody = {
                "auditFamilyId": potentialAuditFamilyID,
                "employeeId": potentialEmployeeID,
            }

            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {



                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Search/GetFamilyPortalActivity', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify(
                                    familyScopedRequestBody
                                )
                            })
                                .then(res => res.json())
                                .then(data => {
                                    console.log("RETURNED DATA from get family Activity: ", data)
                                    /*console.log("RETURNED DATA from get family Activity length: ", data?.length)*/
                                    if (data?.length === 0 || data == [] || data == undefined || data == null) {
                                        console.log("Hitting response weirdness")



                                    } else {
                                        return data;
                                    }

                                })
                                .then((response) => {
                                    if (response.status === 400) {
                                        console.log("hitting 400 error")

                                        fetchData();
                                        throw Error(response.status);

                                    } else {
                                        console.log("FAMILY ACTIVITY FETCH RES: ", response)
                                        setHaveAPIFamilyActivityData(true)

                                        apiFamilyActivityData.current = [response];
                                        handleUpdateFamilyActivityData(apiFamilyActivityData.current)
                                    }
                                    console.log("hitting neither case -- family activity")


                                }).catch((error) => {
                                    console.log(error)


                                })
                        }

                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });

            }





        }
        const fetchFamilyScopedFileData = async () => {

            const familyScopedRequestBody = {
                "auditFamilyId": potentialAuditFamilyID,
                "employeeId": potentialEmployeeID,
            }

            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {



                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Search/GetFamilyFiles', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify(
                                    familyScopedRequestBody
                                )
                            })
                                .then(res => res.json())
                                .then((response) => {
                                    if (response.status === 400) {
                                        console.log("hitting 400 error")

                                        fetchData();
                                        throw Error(response.status);

                                    } else {
                                        console.log("FAMILY FILE ACTIVITY FETCH RES: ", response)
                                        setHaveAPIFamilyFilesUploadedData(true)

                                        apiFamilyFileData.current = [response];
                                        handleUpdateFamilyFileData(apiFamilyFileData.current)
                                    }
                                    console.log("hitting neither case -- family FILE activity")


                                }).catch((error) => {
                                    console.log(error)


                                })
                        }

                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });

            }





        }
        fetchFamilyScopedData();
        fetchFamilyScopedDependentData();
        fetchFamilyScopedActivityData();
        fetchFamilyScopedFileData();

    }, [])
















    return (
        <>

            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', display: { xs: 'none', md: 'block', xl: 'block' } }} >


                {/*  DESKTOP PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>
                    <div className="row-no-left-margin">
                       


                            <Button variant="text"
                                sx={{ mt: 0, }}
                                onClick={() => handleNavigateBackToSearch()}
                            > <IoMdArrowRoundBack />Back to Search</Button>

                       


                    </div>
                   
                    {haveAPISearchData && haveAPIFamilyData && haveAPIFamilyActivityData
                        ? (<>
                            <FamilyDetailsEmployeeCard
                                employeeCardEmployeeFirstName={employeeDataFromSearch[0]?.first_name}
                                employeeCardEmployeeLastName={employeeDataFromSearch[0]?.last_name}
                                employeeCardEmployeeAuditFamilyStatus={familyData[0]?.family_status}
                                employeeCardAuditFamilyId={auditFamilyId}
                                employeeCardAuditEndDate={familyData[0]?.audit_end_date}
                                employeeCardEmailOpInValue={familyActivityData[0]?.email_opt_in}
                                employeeCardNumberOfLogins={familyActivityData[0]?.number_of_logins}
                                employeeCardLastLoginTimestamp={familyActivityData[0]?.last_login_timestamp}
                                employeeCardEmployeeID={employeeId}
                                employeeCardEmployeeEmail={employeeDataFromSearch[0]?.email}
                                employeeCardEmployeeLocation={familyData[0]?.location}
                                employeeCardEmployeePhoneNumber={familyData[0]?.phone_number}
                                employeeCardAddress={employeeDataFromSearch[0]?.address}
                                employeeCardMobileVariant={false}

                            />
                        </>) : (<>
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={400} width={600} />
                            </SkeletonTheme>
                        </>)}
                   


                </Container >

                <Divider sx={{ mt: 2, mb: 2 }} />

                {/* DESKTOP TABLE*/}
                <Container sx={{ mt: 1, mb: 0.5, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>

                    {haveAPIFamilyMemberData
                        ? (<>
                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>Members</Typography>

                            <FamilyDetailsMembersTable
                                tableData={familyMemberData}
                                desiredWidth={'60VW'}
                                desiredHeight={325}

                            />
                        </>) : (<>
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={400} width={600} />
                            </SkeletonTheme>
                        </>)}

                </Container>

                <Divider sx={{ mt: 2, mb: 2 }} />

                <Container sx={{ mt: 1, mb: 0.5, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>

                    {haveAPIFamilyFilesUploadedData

                        ? (<>{familyFileData[0].length !== 0 ? (<>
                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>Files Uploaded</Typography>

                            <FamilyDetailsFilesUploadedTable
                                tableData={familyFileData[0]}
                                desiredWidth={'60VW'}
                                desiredHeight={350}

                            />
                        </>) : (<>
                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>Files Uploaded</Typography>
                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>No File Data Available at this time</Typography>
                        </>)}


                        </>) : (<>
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={400} width={600} />
                            </SkeletonTheme>
                        </>)}

                </Container>

                {/* DESKTOP TABLE*/}
                <Container sx={{ mt: 1, mb: 0.5, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>


                </Container>

                <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

                </Container>






            </Box>
            <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/*  MOBILE PAGE COMPONENTS */}


                {/*  MOBILE PAGE TITLES/HEADINGS*/}

                <Container sx={{ mt: 9, ml: 0, mr: 2, display: { xs: 'block', md: 'none' } }}>
                    <div className="row-no-left-margin">
                       


                            <Button variant="text"
                                sx={{ mt: 0, }}
                                onClick={() => handleNavigateBackToSearch()}
                            > <IoMdArrowRoundBack />Back to Search</Button>

                       


                    </div>
                   
                    {haveAPISearchData && haveAPIFamilyData && haveAPIFamilyActivityData
                        ? (<>
                            <FamilyDetailsEmployeeCard
                                employeeCardEmployeeFirstName={employeeDataFromSearch[0]?.first_name}
                                employeeCardEmployeeLastName={employeeDataFromSearch[0]?.last_name}
                                employeeCardEmployeeAuditFamilyStatus={familyData[0]?.family_status}
                                employeeCardAuditFamilyId={auditFamilyId}
                                employeeCardAuditEndDate={familyData[0]?.audit_end_date}
                                employeeCardEmailOpInValue={familyActivityData[0]?.email_opt_in}
                                employeeCardNumberOfLogins={familyActivityData[0]?.number_of_logins}
                                employeeCardLastLoginTimestamp={familyActivityData[0]?.last_login_timestamp}
                                employeeCardEmployeeID={employeeId}
                                employeeCardEmployeeEmail={employeeDataFromSearch[0]?.email}
                                employeeCardEmployeeLocation={familyData[0]?.location}
                                employeeCardEmployeePhoneNumber={familyData[0]?.phone_number}
                                employeeCardAddress={employeeDataFromSearch[0]?.address}
                                employeeCardMobileVariant={true}

                            />
                        </>) : (<>
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={400} width={350} />
                            </SkeletonTheme>
                        </>)}
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    {haveAPIFamilyMemberData
                        ? (<>
                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 0, mb: 1, color: '#737373' }}>Members</Typography>
                            <FamilyDetailsMembersTable
                                tableData={familyMemberData}
                                desiredWidth={'80VW'}
                                desiredHeight={350}

                            />

                        </>) : (<>
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={400} width={350} />
                            </SkeletonTheme>
                        </>)}
                   
                   
                    <Divider sx={{ mt: 2, mb: 2 }} />

                    {haveAPIFamilyFilesUploadedData

                        ? (<>{familyFileData[0].length !== 0 ? (<>
                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>Files Uploaded</Typography>

                            <FamilyDetailsFilesUploadedTable
                                tableData={familyFileData[0]}
                                desiredWidth={'80VW'}
                                desiredHeight={350}

                            />
                        </>) : (<>
                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>Files Uploaded</Typography>
                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 6, color: '#737373' }}>No File Data Available at this time</Typography>
                        </>)}


                        </>) : (<>
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={400} width={350} />
                            </SkeletonTheme>
                        </>)}



                  


                  


                </Container >



                {/* MOBILE TABLE*/}

                <Grid container spacing={1} sx={{ mt: 1, mb: 2.5, ml: 0.75, mr: 10, display: { xs: 'block', md: 'none', xl: 'none' } }}>
                    <Grid item xs={12}>


                    </Grid>
                </Grid>


            </Box>

        </>

    )
}

export default FamilyDetailsGlobal;


import React, { useState, useEffect, useCallback } from 'react';
import { loginRequest, b2cPolicies } from '../authConfig';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { toast } from 'react-toastify';

function useFetch() {

    const { instance, accounts, inProgress } = useMsal();
    const [responseData, setResponseData] = useState(null); //data return from api call
    const [loading, setLoading] = useState(null);
    const [responseError, setResponseError] = useState(null); //returns error message

    //retrieve id token from b2c
    async function getData(address, options, data){
        setLoading(true);
        setResponseData(null);
        setResponseError(null);
        //retrieve access token from b2c
        
        if (accounts.length > 0) { //if account exists authorize user
            await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                .then(result => {
                    let retry = 5;
                    let counter = 1;
                    let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
                    console.log(result);
                    //api call if token is valid
                    
                    const fetchData = async () => {
                        while (retry > 0) {
                            if (retry < 5) {
                                let d = 2000 * counter;
                                await timeout(d);
                            }
                            if (data !== null) { //data provided
                                await fetch(address, {
                                    method: options,
                                    mode: 'cors',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + result.idToken,
                                    },

                                    body: JSON.stringify(data)
                                })
                                    .then(res => {

                                        if (res.ok) {
                                            retry = 0;
                                            return res.json();
                                        }
                                        if (retry > 1) {
                                            counter++;
                                            retry--;
                                            return;
                                        }
                                        if (retry <= 1) {

                                            toast.error(String(res.status) + " error: Please refresh and try again.", {
                                                position: "bottom-center",
                                                theme: "colored",
                                                autoClose: false,
                                                closeOnClick: true,
                                                toastId: "failedToFetch"
                                            });
                                            retry = 0;
                                            return;
                                        }

                                    })
                                    .then((response) => {
                                        setResponseData(response);
                                        setLoading(false);
                                    }).catch((error) => {
                                        console.log(error)
                                        setResponseError(error);
                                        setLoading(false);
                                    })
                            } else { //no data provided
                                await fetch(address, {
                                    method: options,
                                    mode: 'cors',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + result.idToken,
                                    },
                                })
                                    .then(res => {

                                        if (res.ok) {
                                            retry = 0;
                                            return res.json();
                                        }
                                        if (retry > 1) {
                                            counter++;
                                            retry--;
                                            return;
                                        }
                                        if (retry <= 1) {
                                        
                                            toast.error(String(res.status) + " error: Please refresh and try again.", {
                                                position: "bottom-center",
                                                theme: "colored",
                                                autoClose: false,
                                                closeOnClick: true,
                                                toastId: "failedToFetch"
                                            });
                                            retry = 0;
                                            return;
                                        }
                                    })
                                    .then((response) => {
                                        setResponseData(response);
                                        setLoading(false);
                                    }).catch((error) => {
                                        console.log(error)
                                        setResponseError(error);
                                        setLoading(false);
                                    })
                            }
                        }

                    }

                    fetchData();



                }).catch((error) => {
                    console.log(error) //token expired/is not valid => user is redirected to login page
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }
                    setLoading(false);
                });
        }
    };



    //useEffect(() => {

    //    if (accounts.length > 0) { //if account exists authorize user
    //        getData();
    //    }

    //}, [getData]);

    return { responseData, responseError, loading, getData }; //return data and error
}
export default useFetch;


import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { UserManagementContext } from '../../pages/Counter2';
import Tooltip from "@mui/material/Tooltip";
import { PatternFormat } from 'react-number-format';

import { useForm, Controller } from "react-hook-form";
import { loginRequest, b2cPolicies } from '../../authConfig';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


function UserManagementModalCreateUser({ auditId }) {
    const {
        setUpdateUserModalOpen,
        updateUserModalOpen,
        handleGatherDataAndOpenUpdateUserManagementModal,
        updateUserTableDataOnSubmit,
        setHaveUserData,
        haveUserData
    } = useContext(UserManagementContext);
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    //react-hook-modal setup
    const { instance, accounts, inProgress } = useMsal();

    const {
        register: registerUserModal,
        handleSubmit: handleSubmitUserModal,
        formState: { errors },
        watch: watchUserModal,
        reset: resetUserModal,
        control
    } = useForm({
        defaultValues: {
            email: "",
            givenName: "",
            surname: "",
            auditId: auditId,
            phoneNumber: "",
            displayName: ""
        }
    });

    const watchAllUserModalFields = watchUserModal();

    const onContentUserModalSubmit = (data) => {


        let tempNum = "";

        for (var i = 0; i < data.phoneNumber.length; i++) {
            if (i === 2) {
                tempNum += " ";
            }
            switch (data.phoneNumber[i]) {
                case '(':
                    break;
                case ')':
                    break;
                case ' ':
                    break;
                case '-':
                    break;
                case '+':
                    tempNum += data.phoneNumber[i];
                    break;
                case '1':
                    tempNum += data.phoneNumber[i];
                    break;
                case '2':
                    tempNum += data.phoneNumber[i];
                    break;
                case '3':
                    tempNum += data.phoneNumber[i];
                    break;
                case '4':
                    tempNum += data.phoneNumber[i];
                    break;
                case '5':
                    tempNum += data.phoneNumber[i];
                    break;
                case '6':
                    tempNum += data.phoneNumber[i];
                    break;
                case '7':
                    tempNum += data.phoneNumber[i];
                    break;
                case '8':
                    tempNum += data.phoneNumber[i];
                    break;
                case '9':
                    tempNum += data.phoneNumber[i];
                    break;
                case '0':
                    tempNum += data.phoneNumber[i];
                    break;
                default:
                    break;
            }

        }

        console.log("CREATE USER MODAL DATA: ", data);
        if (accounts.length > 0) { //if account exists authorize user
            const submitUserData =  () => {
                instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        const fetchData = () => {
                            fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/AddUser', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify({ "auditId": data?.auditId, "displayName": data?.displayName, "email": data?.email, "givenName": data?.givenName, "phoneNumber": tempNum, "surname": data?.surname })
                            })
                                .then(res => res.json())
                                .then((response) => {
                                    console.log(response)

                                }).catch((error) => {
                                    console.log(error)
                                })


                        }

                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }

                    });

            }
            submitUserData();
        }


        //console.log("temp num create user -", tempNum)
        //console.log("NEW CREATE USER MODAL DATA: ", { "auditId": data?.auditId, "displayName": data?.displayName, "email": data?.email, "givenName": data?.givenName, "phoneNumber": tempNum, "surname": data?.surname });

        setHaveUserData(!(haveUserData));
        resetUserModal();
        handleCloseModal();


    };


    return (
        <div>
            <Tooltip title="Open Invite User Form">
                <Button variant="contained"
                    aria-label={'Open Invite User form'}
                disableElevation sx={{
                    bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', height: '44px',
                    width: '154px', boxShadow: 0,
                    ':hover': {
                        bgcolor: '#dddddd', // theme.palette.primary.main
                        color: '#ffffff',
                        boxShadow: 0,
                    },

                }} onClick={handleOpenModal}
                style={{ textTransform: 'none' }}
            >
                <Typography fontWeight="400" fontSize="28px" sx={{ mr: 1, mt: '-0.275rem' }} >+</Typography>
                <Typography fontWeight="600" fontSize="18px" >Invite User</Typography>

                </Button>
            </Tooltip>
            <BootstrapDialog
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <BootstrapDialogTitle sx={{ fontWeight: 700, fontSize: '20px' }} id="customized-dialog-title" onClose={handleCloseModal}>
                    Create User
                </BootstrapDialogTitle>
                <form
                    onSubmit={handleSubmitUserModal(
                        onContentUserModalSubmit
                    )}
                >
                    <DialogContent>
                        {/*//FORM CONTENT GOES HERE*/}

                        <div className="modal-row">
                            <Typography
                                id="UserManagementModalCreateUser_FirstName"
                                aria-label={'First Name'}
                                sx={{ color: '#404040', fontSize: '14px', fontWeight: 600 }}>First Name</Typography>

                        </div>

                        <div className="modal-row">
                            <input
                                type="text"
                                aria-labelledby="UserManagementModalCreateUser_FirstName"
                                {...registerUserModal(
                                    "givenName",
                                    {
                                        required: true,
                                    }
                                )}
                                className="modal-input__text--view-name"

                            />
                        </div>

                        <div className="modal-row">
                            <Typography
                                id="UserManagementModalCreateUser_LastName"
                                aria-label={'Last Name'}
                                sx={{ color: '#404040', fontSize: '14px', fontWeight: 600 }}> Last Name</Typography>

                        </div>

                        <div className="modal-row">
                            <input
                                type="text"
                                aria-labelledby="UserManagementModalCreateUser_LastName"
                                {...registerUserModal(
                                    "surname",
                                    {
                                        required: true,
                                    }
                                )}
                                className="modal-input__text--view-name"

                            />
                        </div>

                        <div className="modal-row">
                            <Typography
                                id="UserManagementModalCreateUser_EmailAddress"
                                aria-label={'Email Address'}
                                sx={{ color: '#404040', fontSize: '14px', fontWeight: 600 }}>
                                Email Address
                            </Typography>

                        </div>

                        <div className="modal-row">
                            <input
                                type="text"
                                aria-labelledby="UserManagementModalCreateUser_EmailAddress"
                                {...registerUserModal(
                                    "email",
                                    {
                                        required: true,
                                        pattern: /^[a-zA-Z0-9.!+#$%&amp;&apos;^_`{}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g
                                    }
                                )}
                                className="modal-input__text--view-name"

                            />
                        </div>
                        {errors.email &&
                            <div className="modal-row">
                                <p style={{ color: "#ef4444" }}>Please enter a valid Email Address</p>
                            </div>
                        }
                        <div className="modal-row">
                            <Typography
                                id="UserManagementModalCreateUser_UserName"
                                aria-label={'User Name'}
                                sx={{ color: '#404040', fontSize: '14px', fontWeight: 600 }}> User Name</Typography>

                        </div>

                        <div className="modal-row">
                            <input
                                type="text"
                                aria-labelledby="UserManagementModalCreateUser_UserName"
                                {...registerUserModal(
                                    "displayName",
                                    {
                                        required: true,
                                    }
                                )}
                                className="modal-input__text--view-name"

                            />
                        </div>

                        <div className="modal-row">
                            <Typography
                                tabIndex="0"
                                aria-label={'User Role: Client'}
                                sx={{ color: '#404040', fontSize: '14px', fontWeight: 600 }}>
                                User Role: Client
                            </Typography>
                        </div>

                        {/*<div className="modal-row">*/}
                        {/*    <Typography>User Role</Typography>*/}

                        {/*</div>*/}

                        {/*<div className="modal-row">*/}
                        {/*    <select*/}
                        {/*        aria-label="User Role Select Input"*/}
                        {/*        className="modal-input__text--report-builder-field"*/}
                        {/*        {...registerUserModal(*/}
                        {/*            `umModalUserRole`,*/}
                        {/*            { required: true }*/}
                        {/*        )}*/}
                        {/*    >*/}
                        {/*        <option value="Admin">*/}
                        {/*            Admin*/}
                        {/*        </option>*/}
                        {/*        <option value="Client">*/}
                        {/*           Client*/}
                        {/*        </option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}

                        <div className="modal-row">
                            <Typography
                                id="UserManagementModalCreateUser_PhoneNumber"
                                aria-label={'Phone Number'}
                                sx={{ color: '#404040', fontSize: '14px', fontWeight: 600 }}>Phone Number</Typography>

                        </div>

                        <div className="modal-row">
                            {/*<input*/}
                            {/*    type="text"*/}
                            {/*    aria-labelledby="UserManagementModalCreateUser_PhoneNumber"*/}
                            {/*    {...registerUserModal(*/}
                            {/*        "phoneNumber",*/}
                            {/*        {*/}
                            {/*            required: true,*/}
                            {/*            pattern: /^\+(?:[0-9][\x20-]?){6,14}[0-9]$/g,*/}
                            {/*        }*/}
                            {/*    )}*/}
                            {/*    className="modal-input__text--view-name"*/}

                            {/*/>*/}
                            <Controller
                                control={control}
                                name="phoneNumber"
                                rules={{
                                    required: true,
                                    pattern: /^\+(?:[0-9][\x20-]?)[(][0-9]{3}[)][\x20-][0-9]{3}[-][0-9]{4}$/g,
                                }}
                                render={({ field }) => {

                                    return <PatternFormat
                                        {...field}
                                        className="modal-input__text--view-name"
                                        format="+# (###) ###-####"
                                        allowEmptyFormatting
                                        mask="_"
                                    />
                                }}
                             />
                        </div>
                        {errors.phoneNumber &&
                            <div className="modal-row">
                                <p style={{ color: "#ef4444" }}>Please enter a valid Phone Number '+1 (123) 456-7890'</p>
                            </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" autoFocus onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit" autoFocus>
                            Create
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>

        </div>
    );
}

export default UserManagementModalCreateUser;
import React, { useState, useEffect, PureComponent } from 'react'
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
/*import { Artboard, Card, Collapse, Stats, Table, Progress, RadialProgress, Button } from 'react-daisyui';*/
import { b2cPolicies } from "../authConfig";

function Profile() {

    const { accounts, instance, inProgress } = useMsal();

    const account = accounts[0].idTokenClaims;

    const redirectEditProfile = () => {
        if (inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
        }
    }

    return (
      //<div className="flex flex-wrap items-start justify-items-center">
      //  <Card className="transition ease-in-out bg-base-200 shadow m-4 hover:scale-105">
      //      <Card.Title>Profile</Card.Title>
      //      <Card.Body>
      //          <h1>Name: { account.name }</h1>
      //          <h2>Email: {account.email}</h2>
      //          <h2>Role: "{ account.role }"</h2>
      //          <h2>Given Name: "{account.given_name}"</h2>
      //          <h2>Family Name: "{account.family_name}"</h2>
      //          <Button onClick={redirectEditProfile}>Edit Profile</Button>
      //      </Card.Body>
      //  </Card>
      //</div>
        <></>

  );
}

export default Profile;
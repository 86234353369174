import React, { useState, useEffect, useContext, createContext, useRef } from 'react'
import { useTable } from 'react-table'
import { useParams, useNavigate, Navigate, Link } from "react-router-dom";
import { useForm } from 'react-hook-form'
/*import { Artboard, Card, Collapse, Stats, Table, Progress, RadialProgress, Button, Input, Select, Modal, Dropdown } from 'react-daisyui';*/
import { BsCheckLg, BsXLg, BsExclamationCircleFill, BsThreeDots } from 'react-icons/bs';
import { SidebarContext } from "../App";
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import UserManagementTable from "../components/tables/UserManagementTable";
import UserManagementTableMobile from "../components/tables/UserManagementTableMobile";
import USER_MGMT_MOCK_DATA from "../data/USER_MGMT_MOCK_DATA.json"
import UserManagementModalCreateUser from "../components/modals/UserManagementModalCreateUser";
import UserManagementModalUpdateUser from "../components/modals/UserManagementModalUpdateUser";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext, PermissionsContext , RolesContext } from "../App";
import useFetch from "../hooks/useFetch";
import Tooltip from "@mui/material/Tooltip";
import { loginRequest, b2cPolicies } from '../authConfig';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SearchIcon from '@mui/icons-material/Search';
import { ToastContainer } from 'react-toastify';
export const UserManagementContext = createContext();


function Counter2({ onChange, children }) {

    const {

        setSidebarTitle,
        sidebarActiveClientID,
        setSidebarActiveClientID,
        setSidebarActions
    } = useContext(SidebarContext);

    const { id, auditName } = useParams();
    const { instance, accounts, inProgress } = useMsal();

    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);

    //client
    const [email, setEmail] = useState('');
    //const [password, setPassword] = useState('');
    const [givenName, setGivenName] = useState('');
    const [surname, setSurname] = useState('');
    const [accountEnabled, setAccountEnabled] = useState(false);
    const [currentEmail, setCurrentEmail] = useState('')
    const [displayName, setDisplayName] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [users, setUsers] = useState([]);
    //const users = useRef({});
    const [haveUserData, setHaveUserData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [loading, setLoading] = useState(false);
    //const [status, setStatus] = useState(false);
    const [updateUser, setUpdateUser] = useState(false);
    const { newRoutes, setRoutes } = useContext(RoutesContext);
    //const [fetchNewData, setFetchNewData] = useState(false);
    const navigate = useNavigate();



    const [updateUserModalOpen, setUpdateUserModalOpen] = useState(false);

    useEffect(() => {

        setSidebarTitle(`${auditName}`)
        setSidebarActiveClientID(`${id}`)
    }, [auditName,id])

   

    //toggle visible modal for adding user when on 'add user' button click
    const toggleVisible = () => {
        setVisible(!visible)
    }

    const toggleVisible1 = () => {
        setVisible1(!visible1)
    }

    const toggleVisible2 = () => {
        setVisible2(!visible2)
    }

    const toggleLoading = () => {
        setLoading(!loading)
    }
    

    const [reports, setReports] = useState([]);


    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: '',
            isNested: false
        },
        {
            id: 1,
            label: "Project",
            path: `report/${String(id)}/${auditName}`,
            icon: <BarChartIcon />,
            isActive: false,
            style: '',
            isNested: true
        },
        {
            id: 2,
            label: "Users",
            path: `report/${String(id)}/${auditName}/users`,
            icon: <GroupIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: true
        },
        {
            id: 3,
            label: "Processing",
            path: "processing",
            icon: <AssignmentTurnedInIcon />,
            isActive: false,
            style: '',
            isNested: false
        }
    ]);
    console.log("counter2 - roles - ", roles);
    console.log("counter2 - auditId user - ", permissions[0]?.auditId);
    console.log("counter2 - auditId route - ", id);
    if ((roles === "CLIENT")) {
        /* <Navigate to={`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`} replace />*/
        navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });

    }

    if ((roles === "SERVICES")) {
        navigate('/processing');
    }

    useEffect(() => {
    
        if ({ pageRoutes } != { newRoutes }) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 2,
                    label: "Users",
                    path: `report/${String(id)}/${auditName}/users`,
                    icon: <GroupIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                {
                    id: 3,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 4,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 5,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
               
            ])
           /* console.log("routes - counter2: " + newRoutes.toString());*/
        }
    }, [id, auditName]);

    //const [testUsers, setTestUsers] = useState([
    //    {
    //        "objectId": "05e27995-bb8f-4203-940b-7903804eb475",
    //        "email": "admin20@test.com",
    //        "givenName": "Admin",
    //        "surname": "20",
    //        "displayName": "Admin 20",
    //        "phoneNumber": "+1 1234567890",
    //        "accountEnabled": true,
    //        "finishedRegistration": true
    //    },
    //    {
    //        "objectId": "0b8b9746-5411-41cc-91be-43616c8fc41c",
    //        "email": "admin16@test.com",
    //        "givenName": "Admin",
    //        "surname": "16",
    //        "displayName": "Admin 16",
    //        "phoneNumber": "+1 1234567890",
    //        "accountEnabled": true,
    //        "finishedRegistration": true
    //    },
    //    {
    //        "objectId": "25915e34-0475-43a8-ad81-9c080314d714",
    //        "email": "admin21@test.com",
    //        "givenName": "Admin",
    //        "surname": "21",
    //        "displayName": "Admin 21",
    //        "phoneNumber": "+1 1234567890",
    //        "accountEnabled": true,
    //        "finishedRegistration": true
    //    },
        
    //])

    useEffect(() => {
        if (accounts?.length > 0) { //if account exists authorize user
            const fetchUserData = async () => {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => { 
                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/GetUsers', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify({ "auditId": id })
                            })
                                .then(res => res.json())
                                .then((response) => {
                                   console.log(response)
                                    setHaveUserData(true);
                                    setUsers(response?.b2CUsers);

                                    //users.current = [...response];
                                }).catch((error) => {
                                    console.log(error)
                                })

                           
                        }

                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }

                    });

            }
            fetchUserData();
        }

    }, [haveUserData, setHaveUserData])

    

    

    //add user
    const { register, handleSubmit } = useForm();

    const onSubmit = (data) => {
        data.auditId = id;
       /* console.log(data);*/
        if (accounts.length > 0) { //if account exists authorize user
            instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                .then(result => {
                    fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/AddUser', {//REACT_APP_BACK_URL
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + result.idToken,
                        },
                        body: JSON.stringify(data),
                    })
                        .then(res => res.json())
                        .then((response) => {
                           /* console.log(response)*/
                        })

                }).catch((error) => {
                    console.log(error) //token expired/is not valid => user is redirected to login page
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }

                });
        }
    };


    //update user - change account status
    const { register: register2, handleSubmit: handleSubmit2, watch, setValue } = useForm();
    const onSubmit2 = (data) => {
        //data.auditId = id;
        data.accountEnabled = accountEnabled;
        data.currentEmail = currentEmail;
        console.log(data);
        fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/UpdateUser', {//REACT_APP_BACK_URL
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then((result) => {
               /* console.log(result)*/
            })

    };

    const toggleAccount = (event, user) => {
        
        fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/UpdateUser', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "currentEmail": user.email, "email": user.email, "givenName": user.givenName, "surname": user.surname, "displayName": user.displayName, "phoneNumber": user.phoneNumber, "accountEnabled": !(user.accountEnabled) }),
        })
            .then(res => res.json())
            .then((result) => {
              /*  console.log(result)*/
            })

    };


    const toggleUpdate = (event, user) => {
       
        setCurrentEmail(user.email);
        setAccountEnabled(user.accountEnabled);
        setEmail(user.email);
        setSurname(user.surname);
        setDisplayName(user.displayName);
        setPhoneNum(user.phoneNumber);
        setGivenName(user.givenName);
        toggleVisible1();


    }


    const resendInvite = (event, user) => {
        fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/SendInvite', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "email": user.email, "givenName": user.givenName, "surname": user.surname }),
        })
   /*         .then(res => res.json())*/
            .then((result) => {
               /* console.log(result)*/
            })
    }

    const [updateDataId, setUpdateDataId] = useState("x");
   
    const [updateDataFirstName, setUpdateDataFirstName] = useState("");
    const [updateDataLastName, setUpdateDataLastName] = useState("");
    const [updateDataUserRole, setUpdateDataUserRole] = useState("");
    const [updateDataUserStatus, setUpdateDataUserStatus] = useState(false);
    const [updateDataUserEmail, setUpdateDataUserEmail] = useState("");
    const [updateDataUserName, setUpdateDataUserName] = useState("");
    const [updateDataPhoneNumber, setUpdateDataPhoneNumber] = useState("");
    const [updateDataAccountEnabled, setUpdateDataAccountEnabled] = useState(false);
    function handleGatherDataAndOpenUpdateUserManagementModal(userDataId, userDataFirstName, userDataLastName, userDataUserName, userDataUserRole, userDataUserStatus, userDataUserEmail, userDataPhoneNumber) {
     
        setUpdateDataId(userDataId);
        setUpdateDataFirstName(userDataFirstName);
        setUpdateDataLastName(userDataLastName)
        setUpdateDataUserName(userDataUserName)

        setUpdateDataUserRole(userDataUserRole);
        setUpdateDataUserStatus(userDataUserStatus);
        setUpdateDataUserEmail(userDataUserEmail);
        setUpdateDataPhoneNumber(userDataPhoneNumber);



        setUpdateUserModalOpen(true);
        /*console.log("handleGatherAndOpen: setting Update User modal open: !", updateUserModalOpen);*/
    }

    function updateUserTableDataOnSubmit()
    {
        fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/GetUsers', {//REACT_APP_BACK_URL
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "auditId": id }),
        })
            .then(res => res.json())
            .then((result) => {
               /* console.log(result)*/
                //setUsers(result)
                users.current = [...result];
                //users.current = result;
            })
    }

    const UserManagementContextValue = {
        setUpdateUserModalOpen,
        updateUserModalOpen,
        handleGatherDataAndOpenUpdateUserManagementModal,
        updateUserTableDataOnSubmit,
        setHaveUserData,
        haveUserData
    }


    return (
        <>
            <UserManagementContext.Provider value={UserManagementContextValue}>
                <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', display: { xs: 'none', md: 'block', xl: 'block' } }} >
                    {updateUserModalOpen ? (<>
                        <UserManagementModalUpdateUser
                            userDataId={updateDataId}
                            userDataFirstName={updateDataFirstName}
                            userDataLastName={updateDataLastName}
                            userDataUserName={updateDataUserName}
                            userDataUserRole={updateDataUserRole}
                            userDataUserStatus={updateDataUserStatus}
                            userDataUserEmail={updateDataUserEmail}
                            userDataPhoneNumber={updateDataPhoneNumber}
                            auditId={id}
                            openUpdateModal={true}
                        />
                    </>) : (<></>)}

                    {/*  DESKTOP PAGE TITLES/HEADINGS*/}
                    <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>
                        <div className="row-no-left-margin" style={{ width: "82.5VW" }}>
                            <div className="row-left">
                              
                                {haveUserData ? (<>
                                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> Users</Typography>
                                </>) : (<>
                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                        <Skeleton height={36} width={126} />
                                    </SkeletonTheme>
                                </>)}
                            </div>
                            <div className="row-right">
                               
                                {haveUserData ? (<>
                                    <UserManagementModalCreateUser auditId={id} />
                                </>) : (<>
                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                        <Skeleton height={36} width={72} />
                                    </SkeletonTheme>
                                </>)}
                            </div>
                        </div>

                    </Container >


                    {/* DESKTOP TABLE*/}
                    <Container sx={{ mt: 1, mb: 0.5, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                        {haveUserData ?
                            <UserManagementTable
                                tableData={users}
                                desiredHeight={500}
                            />
                            : <>
                                <div className="row-no-left-margin">
                                    <div className="center">
                                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                            <Skeleton height={360} width={'75VW'} />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </>
                        }
                      
                    </Container>

                    <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

                    </Container>






                </Box>
                <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                    {/*  MOBILE PAGE COMPONENTS */}
                    {updateUserModalOpen ? (<>
                        <UserManagementModalUpdateUser
                            userDataId={updateDataId}
                            userDataFirstName={updateDataFirstName}
                            userDataLastName={updateDataLastName}
                            userDataUserName={updateDataUserName}
                            userDataUserRole={updateDataUserRole}
                            userDataUserStatus={updateDataUserStatus}
                            userDataUserEmail={updateDataUserEmail}
                            userDataPhoneNumber={updateDataPhoneNumber}
                            openUpdateModal={true}
                        />
                    </>) : (<></>)}
                  
                    {/*  MOBILE PAGE TITLES/HEADINGS*/}

                    <Container sx={{ mt: 9, ml: 0, mr: 2, display: { xs: 'block', md: 'none' } }}>
                        <div className="row-no-left-margin">
                            <div className="row-left">
                             
                                {haveUserData ? (<>
                                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> Users</Typography>
                                </>) : (<>
                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                        <Skeleton height={36} width={126} />
                                    </SkeletonTheme>
                                </>)}
                            </div>
                           
                            {haveUserData ? (<>
                                <UserManagementModalCreateUser auditId={id} />
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={72} />
                                </SkeletonTheme>
                            </>)}
                        </div>

                     
                    </Container >



                    {/* MOBILE TABLE*/}

                    <Grid container spacing={1} sx={{ mt: 1, mb: 2.5, ml: 0.75, mr: 10, display: { xs: 'block', md: 'none', xl: 'none' } }}>
                        <Grid item xs={12}>
                          
                            {haveUserData ?
                                <UserManagementTable
                                    tableData={users}
                                    desiredHeight={500}
                                />
                                : <>
                                    <div className="row-no-left-margin">
                                        <div className="center">
                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                <Skeleton height={360} width={'75VW'} />
                                            </SkeletonTheme>
                                        </div>
                                    </div>
                                </>
                            }
                        </Grid>
                    </Grid>


                </Box>
                <ToastContainer
                    limit={1}
                    rtl={false}

                />
            </UserManagementContext.Provider>
        </>

    )
}

export default Counter2

